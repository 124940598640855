import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, Toolbar } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import MembershipBanner from '../../assets/__img_membership_2.jpg';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}

const Membership  = () => {
return (
  <Box position="relative" sx={{ 
    backgroundColor: 'rgba(255, 255, 255, 0.32)',
    minHeight:'calc(100vh - 150px) ',
  }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >
          <Grid container spacing={4} columns={{ xs: 4, md: 12 }}>
       
              <Grid item xs={4} md={6} >
                <Box>
                    <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Mitgliedschaft</span></TitleTexts>
                    <Divider sx={{mb:3}} />
                    <NormalTexts mb={2}>"Sich auf Beziehungen einzulassen, setzt Vertrauen voraus."</NormalTexts>
                    <NormalTexts mb={2}>An eine Mitgliedschaft im German Emirates Club sind verschiedene Voraussetzungen gebunden. Bitte lesen Sie hierzu die <Link to='/Criterions' style={LinkStyled}>Beitrittskriterien</Link> und die <Link to='/Terms' style={LinkStyled}>Allgemeinen Clubbedingungen.</Link></NormalTexts>
                    <NormalTexts mb={2}>In besonderen Ausnahmefällen nimmt der Club auch Interessenten auf, die den Beitrittskriterien nicht oder noch nicht vollständig entsprechen. Hierzu zählen insbesondere Personen mit besonderen Kompetenzen, aber auch Personen des öffentlichen Lebens und solche, die sich um den Club verdient gemacht haben. Über die Aufnahme von Interessenten befindet in diesen Fällen der Mitgliederausschuss.</NormalTexts>
                    <NormalTexts mb={2}>Um ein Vertrauensverhältnis im German Emirates Club zu schaffen und eine Exklusivität zu wahren, bedarf es einer persönlichen Einladung eines bereits bestehenden Clubmitgliedes.</NormalTexts>
                    <NormalTexts mb={2}>Sollten Sie noch keine Einladung in den Club erhalten haben, so können Sie Ihre Freunde und Bekannten bitten, Sie in den Club einzuladen. Sie sind neu in den Emiraten, bzw. kennen niemanden, der Sie einladen könnte? Bitte nehmen Sie Kontakt auf zu unserer Mitgliederhotline unter <a href='tel:+971562050066' style={LinkStyled}>+971 (0)56 2050066</a> (auch per WhatsApp möglich). Wir freuen uns, wenn wir Ihnen helfen können.</NormalTexts>
                    <NormalTexts mb={2}>Weitergehende Fragen richten Sie bitte an unseren <Link to='/Contact?to=membership' style={LinkStyled}>Mitgliederausschuss.</Link>
                    </NormalTexts>
                </Box>
              </Grid>

              <Grid item xs={4} md={6} >
 
                    <Box component='img' src={MembershipBanner} width='100%' />

                    <Toolbar sx={{ backgroundColor:'#efefef', minHeight:'unset', px:1, py:0, mt:2}}>
                      <Typography variant="h6" pl={2} fontWeight={600}> Kurz und knapp </Typography>
                    </Toolbar>
                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Wie kann ich Mitglied werden? </ListTexts> }
                          secondary={
                            <ListTexts>
                              Ausschließlich durch die Einladung eines bestehenden Mitgliedes des German Emirates Club.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Wie kann ich Kontakt aufnehmen?</ListTexts> }
                          secondary={
                            <ListTexts>
                              Über unser <span><Link to='/Contact?to=membership' style={LinkStyled}> Kontaktformular.</Link></span>
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                    </List>
            
              </Grid>

            </Grid>
        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Mitgliedschaft</title>
        <meta name="robots" content="index, follow"/>
        <meta name="Title" content="German Emirates Club | Mitgliedschaft"/>
        <meta name="Keywords" content="Club, Exklusivität, gec, German Emirates Club, kostenfrei"/>
        <meta name="Description" content="Um ein Vertrauensverhältnis im German Emirates Club zu schaffen und eine Exklusivität zu wahren, bedarf es einer persönlichen Einladung eines bereits bestehenden Clubmitgliedes."/>
      </Helmet>
  </Box>
)
}

export default Membership;
