import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, Toolbar } from '@mui/material';
import MarketplaceBanner from '../../assets/__img_marketplace_2.jpeg';
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}

const Marketplace  = () => {
return (
  <Box position="relative" sx={{ 
    backgroundColor: 'rgba(255, 255, 255, 0.32)',
    minHeight:'calc(100vh - 150px) ',
  }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >
          <Grid container spacing={4} columns={{ xs: 4, md: 12 }}>
       
              <Grid item xs={4} md={6} >
                <Box>
                    <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>MarktPlatz</span></TitleTexts>
                    <Divider sx={{mb:3}} />
                    <NormalTexts mb={2}>"Der Marktplatz wurde für unsere Mitglieder zum Kaufen, Verkaufen und Tauschen von Artikeln geschaffen."</NormalTexts>
                    <NormalTexts mb={2}>"Hier können Sie zahlreiche Artikel nach Kategorien geordnet verkaufen und Sie können nach Dingen suchen, von Autos und Antiquitäten über Konzertkarten und Babyartikel bis hin zu Wohngemeinschaften."</NormalTexts>
                    <NormalTexts mb={2}>"Weiterhin gibt es die Möglichkeit Dinge oder z.B. auch Tiere zu verschenken und jeder kann hier nach Schnäppchen Ausschau halten."</NormalTexts>
                    <NormalTexts mb={2}>"SWichtig und stark frequentiert im Marktplatz sind vor allem erfahrungsgemäß die Bereiche Jobangebote und die Jobsuche."</NormalTexts>
                    <NormalTexts mb={2}>"Der Marktplatz ist sehr hilfreich für diejenigen die neu ins Land kommen, um etwas preisgünstig zu erwerben, aber auch für die, die das Land wieder verlassen und vorher noch ihren gesamten Hausstand verkaufen oder verschenken wollen."</NormalTexts>
                </Box>
              </Grid>

              <Grid item xs={4} md={6} >
 
                    <Box component='img' src={MarketplaceBanner} width='100%' />

                    <Toolbar sx={{ backgroundColor:'#efefef', minHeight:'unset', px:1, py:0, mt:2}}>
                      <Typography variant="h6" pl={2} fontWeight={600}> Kurz und knapp </Typography>
                    </Toolbar>
                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Umfangreicher Marktplatz </ListTexts> }
                          secondary={
                            <ListTexts>
                              zum Kaufen, Verkaufen und Tauschen von Artikeln.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Zahlreiche Artikel</ListTexts> }
                          secondary={ 
                            <ListTexts>
                              in Kategorien von Antiquitäten über Konzertkarten bis zu Wohngemeinschaften.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Inserieren</ListTexts> }
                          secondary={
                            <ListTexts>
                              von Jobangeboten und aufgeben von Jobgesuchen.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Gezielte Suche</ListTexts> }
                          secondary={
                            <ListTexts>
                              von Schnäppchen aus vertrauter Hand.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                    </List>
            
              </Grid>

          </Grid>
        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Marktplatz</title>
        <meta name="Title" content="German Emirates Club | Marktplatz"/>
        <meta name="robots" content="index, follow"/>
        <meta name="Keywords" content="Marktplatz, gec, German Emirates Club, Inserieren, Hausstand, Jobangebote, Jobgesuch"/>
        <meta name="Description" content="Der Marktplatz ist sehr hilfreich für diejenigen die neu ins Land kommen, um etwas preisgünstig zu erwerben, aber auch für die, die das Land wieder verlassen und vorher noch ihren gesamten Hausstand verkaufen oder verschenken wollen."/>
      </Helmet>

  </Box>
)
}

export default Marketplace;
