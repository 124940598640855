import React, { useState, useEffect, useCallback } from "react";
import { alpha, styled,InputBase, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, OutlinedInput, useTheme, Theme } from '@mui/material';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Link } from "react-router-dom";
import { ChevronRight} from '@mui/icons-material';
import { GoogleReCaptchaProvider,  useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}
const SInputLabel = styled(InputLabel)(({ theme }) => ({
  '&': {
    color: '#436885',
    fontSize:20,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof InputLabel

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    marginBottom: theme.spacing(3),
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));



const BoxNames = {
  'Login':8,
  'Management':13,
  'Mitgliedschaft':10,
  'Presse':5,
  'Webmaster':9
}
const Contact  = () => {

  useEffect(()=> { loadCaptchaEnginge(6,'white','black');  },[])

  const reloadCaptcha = async (e : any) => {
    loadCaptchaEnginge(6,'white','black');
  };

  const [registrationError, SetRegistrationError] = useState('');
  const formDefault = { value: "",  error: "" };
  const defContactData = {
    name: formDefault,
    email: formDefault,
    phone: formDefault,
    box: formDefault,
    body: formDefault,
    captcha: formDefault
  }
  const [contact, setContact] = useState(defContactData);
  const handleChange = (e : any) => {
    const isError = !e.target.value;
    let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : "";
    if(e.target.name == 'box' && isError) {
      errMsg = 'Bitte treffen Sie eine Auswahl.';
    }
    if(e.target.name == 'email' && !isError && e.target.value.indexOf("@") < 0) {
      errMsg =  'Bitte geben Sie eine gültige Emailadresse ein.';
    }
    setContact(prev => ({ ...prev, [e.target.name]: {  value: e.target.value, error: errMsg  }}));
  }

  const handleSubmit = async (e : any) => {

    SetRegistrationError('');
    e.preventDefault();

    // validate inputs first
    let hasError = false;
    Object.keys(contact).forEach(field => {
      let isError = !contact[field].value;
      let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : '';
      if(e.target.name == 'box' && isError) {
        errMsg = 'Bitte treffen Sie eine Auswahl.';
      }
      if(e.target.name == 'email' && !isError && e.target.value.indexOf("@") < 0) {
        errMsg =  'Bitte geben Sie eine gültige Emailadresse ein.';
      }
      if(errMsg != '') hasError = true;
      setContact(prev => ({ ...prev, [field]: { ...contact[field], error: errMsg } }));
    });
    if(hasError) return;

    // let user_captcha = document.getElementById("user_captcha_input").value;
    // if (validateCaptcha(user_captcha) == true) {

    // }
  };

  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const handleSubmit = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;
  //   }

  //   const token = await executeRecaptcha('yourAction');

  //   // Do whatever you want with the token
  // }, [executeRecaptcha]);

  return (
    // <GoogleReCaptchaProvider reCaptchaKey="6LcjIwQiAAAAAEMCeOlbWQSWTwgic9dV-edGYmh1">
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
        <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
          <Paper sx={{ p: 5}} elevation={20} >

            <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Contact</span></TitleTexts>
            <Divider sx={{mb:3}} />

            <Grid container spacing={2} columns={{ xs: 4, sm:12, md: 12 }}>
        
                <Grid item xs={4} sm={6} md={6} >
                  <Box>
                      

                      <List sx={{ width: '100%'}} >
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon> <ChevronRight /> </ListItemIcon>
                            <ListItemText primary={  <ListTexts> Bitte überprüfen Sie vor der Kontaktaufnahme, ob Ihr Thema bereits in der <Link style={{color:'#436885'}} to={'/Help'} >Hilfe</Link> beantwortet wird.</ListTexts>  } />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon> <ChevronRight /> </ListItemIcon>
                            <ListItemText primary={  <ListTexts> * Bitte füllen Sie das Formular vollständig aus.</ListTexts>  } />
                          </ListItemButton>
                        </ListItem>
                      </List>
              
                  </Box>
                </Grid>

                <Grid item xs={4} sm={6} md={6} >

                    <FormControl variant="standard" sx={{width:'100%'}}>
                        <SInputLabel shrink htmlFor="name-input">
                        Name * <span style={{ marginLeft:5, color: "red" }} > {contact.name.error}</span>
                        </SInputLabel>
                        <BootstrapInput defaultValue="" id="name-input" name="name" onChange={handleChange}  required value={contact.name.value} />
                    </FormControl> 
                    <FormControl variant="standard" sx={{width:'100%'}}>
                        <SInputLabel shrink htmlFor="name-input">
                        Email * <span style={{ marginLeft:5, color: "red" }} > {contact.email.error}</span>
                        </SInputLabel>
                        <BootstrapInput defaultValue="" id="name-input"  name="email" onChange={handleChange}  required value={contact.email.value}   />
                    </FormControl> 
                    <FormControl variant="standard" sx={{width:'100%'}}>
                        <SInputLabel shrink htmlFor="name-input">
                        Mobilfunknummer * <span style={{ marginLeft:5, color: "red" }} > {contact.phone.error}</span>
                        </SInputLabel>
                        <BootstrapInput defaultValue="" id="name-input"  name="phone" onChange={handleChange}  required value={contact.phone.value}  />
                    </FormControl> 
                    <FormControl variant="standard" sx={{width:'100%'}} id={'boxSelect'}>
                        <SInputLabel shrink htmlFor="name-input">
                        Empfänger * <span style={{ marginLeft:5, color: "red" }} > {contact.box.error}</span>
                        </SInputLabel>
                        <Select
                          displayEmpty
                          value={contact.box.value}
                          onChange={handleChange}
                          input={<BootstrapInput />}
                          renderValue={(selected) => { return (selected.length === 0) ? <em>Bitte wählen</em> : selected }}
                        >
                          <MenuItem disabled value={0} selected> <em>Bitte wählen</em> </MenuItem>
                          <MenuItem  value={'Login'} >Login</MenuItem>
                          <MenuItem  value={'Management'} >Management</MenuItem>
                          <MenuItem  value={'Mitgliedschaft'} >Mitgliedschaft</MenuItem>
                          <MenuItem  value={'Presse'} >Presse</MenuItem>
                          <MenuItem  value={'Presse'} >Webmaster</MenuItem>
                        </Select>
                      </FormControl> 
                      <FormControl variant="standard" sx={{width:'100%'}}>
                        <SInputLabel shrink htmlFor="name-input">
                            Nachricht * <span style={{ marginLeft:5, color: "red" }} > {contact.body.error}</span>
                        </SInputLabel>
                        <BootstrapInput defaultValue="" id="contact-body-input" multiline rows={10}  name="body" onChange={handleChange}  required value={contact.body.value} sx={{
                          mb:0
                        }} />
                        <span style={{
                          fontSize:15,
                          color: '#00000080',
                          fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
                          marginBottom: '16px'
                        }}>Noch 500 Zeichen übrig.</span>
                      </FormControl> 
                    
                    <FormControl variant="standard" sx={{width:'100%', mb:'8px'}}>
                        <SInputLabel shrink htmlFor="name-input">
                        Bitte geben Sie in das Feld die Zeichenkombination aus dem Bild ein *
                        </SInputLabel>
                    </FormControl> 
                    <FormControl variant="standard" sx={{width:'100%'}}>
                        <Grid container direction={'row'} spacing={0} alignItems={'flex-end'}>
                          <Grid item xs={6}>
                            <SInputLabel shrink htmlFor="name-input">
                              <span style={{ marginLeft:5, color: "red" }} > {contact.captcha.error}</span>
                            </SInputLabel>
                            <BootstrapInput defaultValue="" id="captcha-input" sx={{width:'100%'}}  name="captcha" onChange={handleChange}  required value={contact.captcha.value}  />
                          </Grid>
                          <Grid item xs={6} alignItems={'flex-end'} m={0} pb={'38px'} pl={'20px'}>
                            <a style={{ color: '#436885', textDecoration:'underline'}} onClick={reloadCaptcha} > Neuen Code laden</a>
                          </Grid>
                        </Grid>
                        
                        <LoadCanvasTemplateNoReload reloadText="Neuen Code laden"  reloadColor="red" />
                        
                    </FormControl> 
                    <FormControl variant="standard" sx={{minWidth:'70px', width:'20%'}}>

                        <Button onClick={handleSubmit} variant="contained" sx={{
                          background: 'linear-gradient(106deg, rgba(255,196,94,1) 0%, rgba(255,113,11,1) 100%)',
                          borderRadius: 3,
                          px: 6,
                          textTransform: 'none',
                          mx:'auto'

                        }}>Absenden</Button>
                        
                        
                    </FormControl> 
                </Grid>

              </Grid>
          </Paper>
        </Container>

        <Helmet>
          <title>German Emirates Club | Kontakt</title>
          <meta name="Title" content="German Emirates Club | Kontakt"/>
          <meta name="robots" content="noindex,follow"/>
        </Helmet>

    </Box>
    // </GoogleReCaptchaProvider> 
  )
}

export default Contact;
