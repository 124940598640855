import React, { useState, useEffect } from "react";
import { makeStyles, styled, Divider, List, ListItemText, Paper, Fab, Grid, Box, Typography, Container } from '@mui/material';
import { Navigation } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    fontWeight:600,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof ListItemText
const MainTitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#001b39',
    fontWeight: 800,
    fontSize:22,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    textAlign: 'center'
  },
})) as typeof ListItemText
const ListTiemText = {
  fontSize:20,
  color: '#000',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
};

const Terms = () => {

  const [ showGoTop, setShowGoTop ] = useState(false);
  const handleVisibleButton = () => {
      setShowGoTop(window.pageYOffset > 500)
  }
  const goToTop = () => {
      window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
  }
  useEffect( () => {
      window.addEventListener( 'scroll', handleVisibleButton )
  }, [] );


  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
      <Container sx={{ mX:0, pX:{xs:1,sm:2,md:0}, py: 4, width: '100%', maxWidth:1274, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >

          <MainTitleTexts>Allgemeine Clubbedingungen</MainTitleTexts>
          
          <Divider sx={{marginBottom:3}} textAlign="left"> <TitleTexts >1. EINLEITUNG</TitleTexts> </Divider>

          <NormalTexts textAlign="justify" mb={2}><strong>1.1</strong> Herzlich Willkommen im German Emirates Club, einem privaten Netzwerk, welches von der Buena Publica FZE angeboten wird. Der German Emirates Club bildet eine Abteilung der Buena Publica FZE.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>1.2</strong> Buena Publica FZE (nachfolgend "Der German Emirates Club") bietet seinen Mitgliedern und Nutzern (nachfolgend "Sie"; "Ihnen") Informationen und Netzwerkplattform auf den Webseiten des German Emirates Clubs (mehrere Top-Level Domains, darunter auch German-Emirates-Club.com) zur Verfügung, unter Geltung der folgenden Bestimmungen und Bedingungen (Allgemeinen Clubbedingungen oder "ACB"). Mit der elektronischen Anmeldung Ihrer Mitgliedschaft bestätigen Sie, dass Sie diese Nutzungs- und Mitgliedsbedingungen gelesen und akzeptiert haben und sich uneingeschränkt und abschließend bereit erklären, diese zu beachten und anzuerkennen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>1.3</strong> German Emirates Club und seine zugehörigen Unternehmen und Lizenzgeber besitzen und bewahren alle registrierten und nicht registrierten Rechte an der Internetseite des German Emirates Club und seiner Dienste, sowie der eingesetzten Marken, Logos und anderem intellektuellem Eigentum. "German Emirates Club" und "German-Emirates-Club.com" sind Marken des German Emirates Club. Sämtliche Urheberrechte der Inhalte der Internetseiten des German Emirates Clubs stehen dem German Emirates Club zu.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>1.4</strong> Im Falle eines Verstoßes gegen diese Allgemeinen Clubbedingungen, insbesondere gegen die Beitrittskriterien oder Nutzungsbedingungen, des German Emirates Club, behält sich der German Emirates Club das Recht vor, nach seinem Ermessen, die Mitgliedschaft einstweilen stillzulegen oder zu kündigen.</NormalTexts>
          
          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >2. MITGLIEDSCHAFTSBEDINGUNGEN und BEITRITTSKRITERIEN</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={0}><strong>2.1</strong> Zur Wahrung der Exklusivität des Clubs und zur Schaffung eins Vertrauensverhältnisses unter den Mitgliedern, sowie zwischen Mitglied und Club, stellt der German Emirates Club kumulativ folgende Bedingungen an eine Aufnahme in den Club:</NormalTexts>
          <Box pl={2} mb={2}>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }} >
              <ListItemText style={ListTiemText}  primary="Die Aufnahme in den German Emirates Club kann nur auf Einladung eines bestehenden Clubmitglieds erfolgen." />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Mitglieder müssen das 18. Lebensjahr vollendet haben." />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Der Club möchte Deutschsprachige, die in den Vereinigten Arabischen Emiraten leben, zu einer Gemeinschaft zusammenzuführen. Somit ist die deutsche, österreichische oder schweizerische Staatsbürgerschaft die Grundvoraussetzung für eine Clubmitgliedschaft." />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Eine weitere Aufnahmevoraussetzung ist der Besitz eines Residenzvisums, das zum ständigen Aufenthalt in den Vereinigten Arabischen Emiraten legitimiert." />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Der Club lehnt Bewerber und Mitglieder ab, die vorbestraft sind oder während der Mitgliedschaft straffällig werden." />
            </List>
          </Box>
          <NormalTexts textAlign="justify" mb={2}><strong>2.2</strong> Der German Emirates Club behält sich diesbezüglich Nachprüfungen sowie das Recht vor Mitglieder bei Falschangaben auszuschließen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>2.3</strong> Die internen Internetseiten des German Emirates Club stehen nicht der Öffentlichkeit zur Verfügung, und sind nur auf Einladung eines bestehenden Mitglieds des German Emirates Club zugänglich.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>2.4</strong> Mitglieder des Clubs haben eingeschränkt die Möglichkeit Personen in den Club einzuladen, sofern diese Personen den Beitrittskriterien entsprechen. Der German Emirates Club erwartet von seinen Mitgliedern, dass sie nur angesehene Personen aus ihrem eigenen realen Bekanntenkreis oder Familienkreis in den German Emirates Club einladen und solche Personen außen vor lassen, die das Ansehen des German Emirates Club und seiner Mitgliedschaft in Verruf bringen, schaden oder negativ beeinflussen könnten.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>2.5</strong> Durch Ihre Mitgliedschaft im German Emirates Club bestätigen Sie, dass Sie die Mitgliedschaftsbedingungen und Beitrittskriterien anerkennen. Als Mitglied dürfen Sie maximal ein Mitgliedsprofil haben. Sollten Sie aus irgendeinem Grund von einer Mitgliedschaft ausgeschlossen worden sein, ist es Ihnen nicht gestattet, ein Alternativprofil einzurichten. Verkaufs- und Handelsaktivitäten (abgesehen von den auf dem Modul Marktplatz zur Verfügung stehenden Funktionen), das Übertragen eines Mitgliedsprofils auf eine andere Person oder die Benutzung eines Profils, das nicht das Ihre ist, sind nicht gestattet. Sollte ein Mitglied gegen eine dieser Bedingungen verstoßen, ist der Club berechtigt die Mitgliedschaft zu kündigen und das Mitgliedsprofil zu löschen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>2.6</strong> Ihre Teilnahme im German Emirates Club basiert auf der Annahme, dass alle von Ihnen angegebenen Informationen zu Ihrer Person der Wahrheit entsprechen. Bei Angabe irreführender oder falscher Informationen ist der German Emirates Club berechtigt, Ihr Mitgliedsprofil auf den Internetseiten des Clubs stillzulegen und Ihre Mitgliedschaft zu kündigen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>2.7</strong> Die Verwendung von Aliassen, Künstlernamen oder Pseudonymen ist nicht gestattet. Als Mitgliedsprofilfoto dürfen ausschließlich Fotos Verwendung finden, die das jeweilige Clubmitglied zeigen. Die Verwendung von Gruppenfotos, Fotos oder Abbildungen anderer oder nicht existierender Personen oder anderer Wesen (Tiere, Landschaften, Fantasiewesen, Zeichnungen oder Grafiken etc.), sind als Mitgliedsprofilfoto nicht gestattet. Gleiches gilt für Firmenlogos, es sei denn dies wurde seitens des German Emirates Club im Vorwege ausdrücklich und schriftlich genehmigt.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>2.8</strong> Der German Emirates Club behält sich das Recht vor, jederzeit die Mitgliedschaftsbedingungen und Beitrittskriterien ohne Vorankündigung zu ändern oder anzupassen.</NormalTexts>
          
          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >3. NUTZUNGS- UND SERVICEBEDINGUNGEN</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={2}><strong>3.1</strong> Die Benutzung der Internetseiten des German Emirates Clubs sowie erfolgt auf Basis dieser Allgemeinen Clubbedingungen. Der German Emirates Club kann sein Angebot ohne Vorankündigung zurückziehen oder eine Mitgliedschaft ablehnen, bzw. kündigen, insofern gegen diese ACB verstoßen wurde.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.2</strong> Der German Emirates Club ist für den privaten und persönlichen Gebrauch bestimmt. Unternehmen oder andere juristische Personen dürfen den German Emirates Club nur mit ausdrücklicher vorhergehender schriftlicher Genehmigung benutzen. Eine Mitgliedschaft kommt für juristische Personen nicht in Betracht.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.3</strong> Der German Emirates Club stellt die folgenden Module mit dem Ziel der Förderung des Informationsaustausches und der Bildung von Netzwerken zwischen deutschsprachigen Bewohnern der Vereinigten Arabischen Emirate zur Verfügung: Kommunikationsplattform, Marktplatz, Forum, Services und Events Plattform.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.4</strong> Der German Emirates Club stellt die vom Nutzer bereitgestellten Daten und/oder Informationen den anderen Nutzern nur zur Verfügung, soweit diese Daten und/oder Informationen nicht gegen gesetzliche Vorschriften oder diese ACB verstoßen. Der German Emirates Club behält sich das Recht vor, von Ihnen oder anderen Mitgliedern eingestellte Inhalte nach eigenem Ermessen und ohne Vorankündigung zu entfernen, insbesondere wenn diese unter anderem illegaler, bedrohender, diffamierender, betrügerischer oder obszöner Natur sind oder in anderer Weise inakzeptabel sind, weil sie gegen intellektuelles Eigentum oder die Privatsphäre anderer verstoßen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.5</strong> Das Mitglied erkennt an, dass eine 100 %-ige Verfügbarkeit der Internetseiten des German Emirates Club technisch nicht zu realisieren ist. Der German Emirates Club bemüht sich jedoch, seine Internetseiten möglichst konstant verfügbar zu halten. Insbesondere Wartungs-, Sicherheits- oder Kapazitätsbelange, sowie Ereignisse, die nicht im Machtbereich des German Emirates Club stehen (wie z. B. Störungen von öffentlichen Kommunikationsnetzen, Stromausfälle etc.), können zu kurzzeitigen Störungen oder zur vorübergehenden Einstellung der Dienste auf den Internetseiten führen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.6</strong> Der German Emirates Club bietet dem Nutzer eine Plattform, um Mitglieder mit anderen Mitgliedern oder Mitglieder mit Partnern des Clubs zusammenzuführen, und stellt hierfür nur die technischen Applikationen bereit, die eine generelle Kontaktaufnahme der Mitglieder untereinander ermöglichen. Sofern die Mitglieder über die Internetseiten des Clubs untereinander Verträge schließen oder Mitglieder mit Partnern des Clubs Verträge schließen, ist der German Emirates Club hieran nicht beteiligt und für die Erfüllung solcher Verträge sowie für daraus resultierende Pflichtverletzungen in keiner Weise verantwortlich oder haftbar. Die Mitglieder, Nutzer oder Partner sind für die Abwicklung und die Erfüllung der geschlossenen Verträge allein verantwortlich.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.7</strong> Der German Emirates Club ist bemüht, die Inhalte auf seiner Internetseite zu überwachen. Mit Benutzung der Internetseite erklären Sie, dass Sie sich darüber bewusst sind, dass Sie auf Material stoßen können, mit dem Sie möglicherweise nicht einverstanden sind oder das Sie anstößig finden. Sie bestätigen, dass Sie German Emirates Club hierfür nicht haftbar machen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.8</strong> Die Richtigkeit, Wahrheit oder Verlässlichkeit der Inhalte auf den Internetseiten des German Emirates Clubs sei es in den Mitgliederprofilen, in den Foren oder andernorts wird von German Emirates Club nicht bestätigt, gewährleistet oder garantiert. Der German Emirates Club unterstützt keinerlei Meinungen, die im Forum oder anderswo durch einzelne Mitglieder vertreten werden.Der German Emirates Club behält sich das Recht vor, die Internetseite oder seine Angebote ohne Vorankündigung zu ändern oder Gebühren für Angebote oder für bestimmte Funktionen zu erheben, sowie Gebühren oder Angebote ohne Vorankündigung zu ändern.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.9</strong> Der German Emirates Club behält sich das Recht vor, die Internetseite oder seine Angebote ohne Vorankündigung zu ändern oder Gebühren für Angebote oder für bestimmte Funktionen zu erheben, sowie Gebühren oder Angebote ohne Vorankündigung zu ändern.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.10</strong> Durch das Einstellen von Inhalten oder Informationen auf seine Internetseite, gewähren Sie German Emirates Club automatisch das Recht, diese Inhalte weltweit, unbeschränkt, gebührenfrei, unbefristet, unwiderruflich auf seiner Internetseite zu veröffentlichen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>3.11</strong> Externe Links: Der German Emirates Club stellt Links zu Webseiten Dritter (externe Links) zur Verfügung, deren Inhalt über die Kontrollmöglichkeiten des German Emirates Clubs hinausgeht. Der German Emirates Club ist für Inhalte, Produkte, Dienstleistungen oder sonstigem Material externer Links nicht verantwortlich oder haftbar, ebenso wenig für Schäden oder Verluste die aus der Nutzung dieser Inhalte, Produkte, Dienstleistungen oder sonstigem Material resultieren.</NormalTexts>

          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >4. PFLICHTEN ALS MITGLIED</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={2}><strong>4.1</strong> Der German Emirates Club wünscht sich für seine Mitglieder eine freundliche, vertraute und sichere Gemeinschaft. Dies ist eine private Internetseite, auf der Mitglieder Privatsphäre und Vertrauen erwarten. Der German Emirates Club ist eine bunt gemischte Gemeinschaft, dementsprechend unterschiedlich sind Erwartungen und Verhalten der Mitglieder. Um dennoch ein für alle Mitglieder angenehmes Klima zu schaffen, hat der German Emirates Club Regeln aufgestellt. Mit der Mitgliedschaft am German Emirates Club und der Nutzung der Internetseiten des German Emirates Club bestätigen Sie, diese Regeln verstanden zu haben, sie zu akzeptieren und einzuhalten.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.2</strong> Jedes Mitglied hat sich vor Inanspruchnahme der Dienste der Internetseiten des German Emirates Clubs zu registrieren.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.3</strong> Das Mitglied sichert zu, dass alle von ihm bei der Registrierung angegebenen Daten wahr und vollständig sind und ist verpflichtet, Änderungen seiner Nutzerdaten unverzüglich anzuzeigen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.4</strong> Bei der Anmeldung wählt das Mitglied ein Passwort und ist verpflichtet, sein Passwort geheim zu halten. Der German Emirates Club wird das Passwort nicht an Dritte weitergeben und das Mitglied zu keinem Zeitpunkt nach dem Passwort fragen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.5</strong> Durch den Abschluss des Registrierungsvorganges gibt das Mitglied seine abschließende Zustimmung zu diesen ACB und bestätigt die Mitgliedschaft im German Emirates Club. Des Weiteren stimmt das Mitglied unwiderruflich zu, dass seine persönlichen Angaben auch für andere Mitglieder sichtbar werden.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.6</strong> Der German Emirates Club kann technisch nicht mit Sicherheit feststellen, ob ein auf den Internetseiten des German Emirates Club angemeldetes Mitglied tatsächlich diejenige Person darstellt, die das Mitglied vorgibt zu sein. Der German Emirates Club leistet daher keine Gewähr für die tatsächliche Identität eines Mitglieds. Jedes Mitglied hat sich daher selbst von der Identität eines anderen Mitglieds zu überzeugen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.7</strong> Zu keinem Zeitpunkt und zu keinem Zweck, Anlass oder zur Werbung dürfen Sie den Namen oder das Logo des German Emirates Club verwenden, es sei denn der German Emirates Club hat dieser Verwendung ausdrücklich zugestimmt.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.8</strong> Vertrauen wird im German Emirates Club groß geschrieben. Falschangaben zu Ihrer Person, die Benutzung von Aliassen oder Pseudonymen, sowie unwahre Angaben in Ihrem Profil, führen zu einer Stilllegung und zur Löschung Ihres Mitgliedskontos.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.9</strong> Es ist nicht gestattet, Daten von der Internetseite des German Emirates Clubs zu überwachen, Inhalte oder Seiten zu kopieren, zu sammeln, zu archivieren oder persönliche Daten oder Hinweise auf andere Mitglieder zu verkaufen oder mit Ihnen Handel zu treiben.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.10</strong> Es ist nicht erlaubt, Anwendungsprogramme wie Robots, Spider, Datenwiderherstellungsprogramme oder Programme, die Daten des German Emirates Club kopieren, ausfindig machen oder archivieren, einzusetzen und zu benutzen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.11</strong> Es ist nicht gestattet, elektronische oder gedruckte Inhalte der Internetseite des German Emirates Club, sowie die Namen der Mitglieder zu verbreiten oder zu veröffentlichen, es sei denn der German Emirates Club hat dem ausdrücklich zugestimmt. Es ist ebenso wenig gestattet, den Inhalt der Internetseite mit Personen, die nicht Mitglied im German Emirates Club sind, zu teilen, an andere zu übermitteln oder gar zu verkaufen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.12</strong> Der German Emirates Club soll vor unerwünschten Faktoren bewahrt werden. Verbreiten und versenden Sie daher nicht ungefragt Werbung oder ungenehmigte Werbung, Werbematerialien, Spam-Emails, Kettenbriefe, Schneeballsysteme oder jegliche Art von Werbung an andere Mitglieder. Ebenso ist es zu unterlassen, anderen Mitgliedern nachzustellen, sie zu belästigen oder zu stören.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.13</strong> Sie sind allein verantwortlich für die Geheimhaltung und Nutzung Ihrer Kontodaten, ihren Benutzernamen und Passwort. Sie sind ebenso verantwortlich für alle Aktivitäten, die von Ihrem Konto ausgehen, ungeachtet dessen, ob diese mit oder ohne Ihr Wissen oder Ihre Zustimmung ausgeführt wurden. Wenn Sie einer anderen Person wissentlich Ihre Zugangsdaten mitteilen, kann Ihre Mitgliedschaft zeitweise oder ganz aufgehoben werden. German Emirates Club haftet nicht für von Ihrem Konto ausgehende Aktivitäten, wenn der Zugriff durch die Eingabe Ihres Benutzernamen und Passwortes erfolgt ist. Sollten Sie die unbefugte Nutzung ihres Mitgliedskontos durch unbekannte Dritte entdecken, sind sie verpflichtet hiervon unverzüglich den German Emirates Club zu unterrichten</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.14</strong> Der German Emirates Club darf in keinem Land und unter keiner Rechtsordnung für illegale Aktivitäten benutzt werden.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.15</strong> Es ist keinem Mitglied gestattet, Inhalte einzustellen oder Diskussionen im Forum zu beginnen, die vom German Emirates Club als beleidigend, unanständig, missbräuchlich, obszön, diskriminierend, unrechtmäßig oder in anderer Art verwerflich betrachtet werden. Es ist dem Mitglied insbesondere untersagt, beleidigende oder verleumderische Inhalte zu verwenden, unabhängig davon, ob diese Inhalte andere Mitglieder, den German Emirates Club, deren Mitarbeiter oder Partner oder andere Personen oder Unternehmen betreffen, pornografische oder gegen Jugendschutzgesetze verstoßende Inhalte zu verwenden oder pornografische oder gegen Jugendschutzgesetze verstoßende Produkte oder Dienstleistungen zu bewerben, anzubieten oder zu vertreiben, andere Mitglieder unzumutbar (insbesondere durch Spam) zu belästigen sowie wettbewerbswidrige Handlungen vorzunehmen oder zu fördern, einschließlich progressiver Kundenwerbung (wie Ketten­-, Schneeball- oder Pyramidensysteme).</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>4.16</strong> Es ist nicht gestattet, Inhalte oder Diskussionen im Forum einzustellen oder zu initiieren oder gesetzlich geschützte Inhalte zu verwenden oder gesetzlich geschützte Waren oder Dienstleistungen zu bewerben anzubieten oder zu vertreiben ohne dazu berechtigt zu sein, die Patente, Marken (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht), Geschäftsgeheimnisse, Urheberrechte oder andere Besitzstandsrechte, ungeachtet ob persönlicher, geschäftlicher oder staatlicher Natur, von Dritten verletzen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={0}><strong>4.17</strong> Die folgenden belästigenden Handlungen sind ausdrücklich zu unterlassen, auch wenn diese konkret keine Gesetze verletzen sollten:</NormalTexts>
          <Box pl={2} mb={2}>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Versendung von Kettenbriefen," />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Versendung identischer privater Nachrichten an mehrere Nutzer gleichzeitig," />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Durchführung, Bewerbung und Förderung von Strukturvertriebsmaßnahmen (wie Multi-Level-Marketing oder Multi-Level-Network-Marketing), oder" />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="anzügliche oder sexuell geprägte Kommunikation (explizit oder implizit)." />
            </List>
          </Box>
          <NormalTexts textAlign="justify" mb={0}><strong>4.18</strong> Folgende Handlungen sind dem Nutzer der Internetseite des German Emirates Clubs ausdrücklich untersagt:</NormalTexts>
          <Box pl={2} mb={2}>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Verwendung von Mechanismen, Software oder Scripts in Verbindung mit der Nutzung der Internetseiten des German Emirates Club. Die Nutzer der Internetseiten des German Emirates Club dürfen jedoch die Schnittstellen oder Software nutzen, die ihm im Rahmen der auf den Internetseiten des German Emirates Club angebotenen Dienste des German Emirates Club zur Verfügung gestellt werden." />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Blockieren, Überschreiben, Modifizieren, Kopieren, soweit dies nicht für die ordnungsgemäße Nutzung der Dienste der Internetseiten des German Emirates Club erforderlich ist. Das Kopieren im Wege von &Robot/Crawler sowie anderer Suchmaschinen-Technologien ist z. B. nicht erforderlich für die ordnungsgemäße Nutzung der Dienste der Internetseiten des German Emirates Club und daher ausdrücklich untersagt." />
            </List>
            <List sx={{ display: 'list-item', listStyleType: 'disc' }}>
              <ListItemText style={ListTiemText}  primary="Die Verbreitung und öffentliche Wiedergabe von Inhalten der Internetseiten des German Emirates Club oder von anderen Mitgliedern sowie jede Handlung, die geeignet ist, die Funktionalität der Internetseiten-Infrastruktur des German Emirates Club zu beeinträchtigen, insbesondere diese übermäßig zu belasten." />
            </List>
          </Box>

          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >5. DATENSCHUTZ</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={2}><strong>5.1</strong> Der German Emirates Club basiert auf Vertrauen und Privatsphäre. Sämtliche persönlichen Informationen, die dem German Emirates Club zur Verfügung gestellt wurden, werden mit äußerster Diskretion und Sorgfalt behandelt.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>5.2</strong> Der German Emirates Club wird persönliche Daten von Mitgliedern nicht ohne das ausdrückliche Einverständnis des jeweiligen Mitglieds an Dritte weitergeben.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>5.3</strong> Der German Emirates Club ist bemüht, ein vertrauensvolles und sicheres Umfeld zu schaffen. Jedoch übernimmt der German Emirates Club keine Verantwortung dafür, dass andere Mitglieder die von Ihnen auf der Internetseite eingestellten Inhalte kopieren und andernorts veröffentlichen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>5.4</strong> Der Zugang zu Ihren privaten Kontodaten ist nur den Webmastern und der Leitung des German Emirates Club vorbehalten. Keine anderen Mitarbeiter, Partner, Mitglieder oder Dritte können diese Informationen ohne Ihre ausdrückliche Erlaubnis einsehen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>5.5</strong> Der German Emirates Club kann die Inhalte Ihres Mitgliederprofils einsehen, speichern und zugänglich machen, wenn dies juristisch notwendig ist oder der German Emirates Club in dem Glauben ist, dass derartige Schritte für die Einhaltung juristischer Bestimmungen oder zum Schutz der Rechte und des Eigentums vom German Emirates Club notwendig sind.</NormalTexts>

          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >6. KÜNDIGUNG IHRER CLUBMITGLIEDSCHAFT</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={2}><strong>6.1</strong> Mitglieder können Ihre Mitgliedschaft jederzeit ohne Angabe von Gründen kündigen. Die Kündigung der Mitgliedschaft im German Emirates Club kann mit dem auf den Internetseiten des German Emirates Clubs aus erreichbaren Kontaktformular erfolgen. Ihre Profilangaben werden von der Internetseite entfernt und können nicht mehr eingesehen werden. Bei der Kündigung sind der Benutzername und eine auf den Internetseiten des German Emirates Club registrierte E-Mail-Adresse des Nutzers anzugeben.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>6.2</strong> Der German Emirates Club kann nach eigenem Ermessen und zu jeder Zeit ohne Angabe von Gründen Ihre Mitgliedschaft beenden und den Zugang zur Internetseite sperren. Jede Kündigung der Mitgliedschaft von Seiten des German Emirates Club geschieht im Interesse des Zweckes des German Emirates Club oder im Interesse der ganzen Gemeinschaft.  </NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>6.3</strong> Die Besitzstandsrechte des German Emirates Clubs, Haftungsausschluss, Entschädigungen, Haftungsbeschränkungen und andere Bestimmungen dieser Allgemeinen Clubbedingungen gelten auch für die Zeit nach Ende der Mitgliedschaft.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>6.4</strong> Nach der Kündigung Ihrer Mitgliedschaft können Sie dem German Emirates Club als Mitglied nur mit ausdrücklicher Zustimmung und auf Einladung des Mitgliederausschusses wieder beitreten.</NormalTexts>

          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >7. HAFTUNGSAUSCHLUSS UND EINSCHRÄNKUNGEN</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={2}><strong>7.1</strong> Der German Emirates Club und seine angeschlossenen Unternehmen, Lizenzgeber, Partner, Lieferanten, Berater und Vermittler ("German Emirates Club") sind für Genauigkeit, Inhalt, Vollständigkeit, Legalität, Verlässlichkeit, Funktionstüchtigkeit oder Verfügbarkeit von Informationen oder Materialien, die durch Mitglieder und Nutzer auf der Gesamtheit der Internetseiten des German Emirates Club bereitgestellt werden weder verantwortlich noch haftbar. Der German Emirates Club ist ferner für jegliches Verhalten seiner Mitglieder nicht verantwortlich oder haftbar.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>7.2</strong> Der Service des German Emirates Clubs, alle Materialien, Informationen (darin unbeschränkt enthalten alle Informationen oder Materialien, die durch die Benutzung der Internetseiten des German Emirates Club zugänglich und erhältlich waren), Produkte und Dienstleistungen werden angeboten “wie sie sind” und es wird vom German Emirates Club keinerlei Garantie oder Haftung übernommen. Der German Emirates Club lehnt ausdrücklich und in vollem Umfang, sofern juristisch möglich, jegliche ausdrückliche Zustimmung, gesetzliche Gewährleistung und gesetzliche Haftung ab. Dies gilt ebenfalls uneingeschränkt für die Zusicherung der Gebrauchstauglichkeit, Eignung für einen bestimmten Zweck und die Beachtung von Besitzstandsrechten.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>7.3</strong> Einige Rechtsordnungen verbieten den Haftungsausschluss oder die Haftungsbeschränkung gesetzlicher Gewährleistung. In diesem Fall gelten die obigen Ausschlüsse und Einschränkungen gegebenenfalls für Sie nicht.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>7.4</strong> Die Nutzer der Internetseiten des German Emirates Club sowie Mitglieder des German Emirates Club erklären sich damit einverstanden, dass die Benutzung der Internetseiten des German Emirates Club auf eigenes Risiko erfolgt. Sie erklären sich einverstanden den German Emirates Club gegen etwaige Ansprüche Dritter, die auf irgendeine Weise aus der Inanspruchnahme der Dienste oder der Benutzung der Internetseiten von German Emirates Club, entstehen, schadlos zu halten. Dies gilt ebenso für die Haftung oder Kosten, die aus Ansprüchen, Verlusten oder Schäden (tatsächliche und Folgeschäden), Rechtsstreitigkeiten, Urteilen, Prozess- und Anwaltskosten jeglicher Art entstehen.</NormalTexts>

          <Divider sx={{marginBottom:2}} textAlign="left"> <TitleTexts >8. ALLGEMEINE BESTIMMUNGEN</TitleTexts> </Divider>
          <NormalTexts textAlign="justify" mb={2}><strong>8.1</strong> Abschließende Regelung: Diese Allgemeinen Clubbedingungen regeln das gesamte Verhältnis zwischen dem Nutzer der Internetseiten oder dem Mitglied des German Emirates Club und dem German Emirates Club bezüglich der vorgenannten Themen abschließend, mit Ausnahme der auf den Webseiten des German Emirates Club vorgesehenen speziellen Bedingungen für bestimmte Nutzungen, insbesondere für die Nutzung des Forums und des Marktplatzes, welche ergänzend neben die Allgemeinen Clubbedingungen treten. Die ACB ersetzen alle vorherigen oder gegenwärtigen, hierzu getroffenen, mündlichen oder schriftlichen Absprachen oder Vereinbarungen. Die Aussetzung irgendeiner Bestimmung dieser Allgemeinen Clubbedingungen ungeachtet welcher Seite, bedarf der Schriftform und muss von einer Seite unterschrieben sein. Im Falle der Aussetzung einer oder mehrerer Bestimmungen dieser Allgemeinen Clubbedingungen seitens eines Nutzers der Internetseiten oder eines Mitgliedes behält sich der German Emirates Club die Untersagung der Nutzung der Internetseiten des German Emirates Club bzw. die Kündigung der Mitgliedschaft vor.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.2</strong> Änderungsvorbehalt: Der German Emirates Club behält sich das Recht vor, diese Allgemeinen Clubbedingungen ohne Vorankündigung und ohne Nennung von Gründen jederzeit zu ändern oder zu ergänzen. Durch Ihre hierauf folgende fortgesetzte Benutzung der Internetseite in Ihrer Eigenschaft als Mitglied bestätigen Sie, dass Sie mit den vorgenommenen Änderungen einverstanden sind und dass die jeweils geltenden ACB für Sie verpflichtend sind. Der German Emirates Club empfiehlt seinen Mitgliedern daher, die Allgemeinen Clubbedingungen regelmäßig zu lesen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.3</strong> Abtretung: Die Mitgliedschaft bzw. das Nutzungsverhältnis ist für das Mitglied/den Nutzer ein persönliches. Sie/es ist nicht vererbbar. Ferner, ist es dem Mitglied nicht möglich, diese ACB oder daraus entstehende Rechte und Pflichten ganz oder in Teilen abzutreten, sei es aus eigener Motivation oder gesetzlich, ohne ausdrückliche vorherige Zustimmung durch den German Emriates Club. Jegliche Vertragsübernahme oder Abtretung in Bezug auf die Mitgliedschaft ist nichtig und findet keine Anwendung. Buena Publica FZE ist es gestattet sämtliche Rechte und Pflichten aus dem Vertragsverhältnis abzutreten, zu übertragen oder zu delegieren.Widersprechende Klauseln: Wo ein Widerspruch zwischen einzelnen Bestimmungen dieser ACB und besonderer Bestimmungen für die Nutzung einzelner Teilbereiche oder Module der Internetseiten des German Emirates Clubs bestehen oder zu bestehen scheinen, wird den besonderen Bestimmungen für die Nutzung dieser Teilbereiche oder Module Vorrang vor den widersprechenden Bestimmungen der ACB im Hinblick auf die Nutzung des entsprechenden Teilbereichs oder Moduls eingeräumt.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.4</strong> Widersprechende Klauseln: Wo ein Widerspruch zwischen einzelnen Bestimmungen dieser ACB und besonderer Bestimmungen für die Nutzung einzelner Teilbereiche oder Module der Internetseiten des German Emirates Clubs bestehen oder zu bestehen scheinen, wird den besonderen Bestimmungen für die Nutzung dieser Teilbereiche oder Module Vorrang vor den widersprechenden Bestimmungen der ACB im Hinblick auf die Nutzung des entsprechenden Teilbereichs oder Moduls eingeräumt.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.5</strong> Verzicht: Eine Nichtwahrnehmung von Rechten aus den ACB durch den German Emirates Clubs bzw. besonderen Nutzungsbedingungen für Teilbereiche oder Module z.B. die Gewährung von Fristverlängerungen, sei es ausdrücklich oder stillschweigend, führt in keinem Falle zu einem Verzicht auf diese Rechte des German Emirates Clubs weder für die Zukunft noch den gegenwärtigen Gegenstand, es sei denn, dass der German Emirates Club ausdrücklich einen solchen Verzicht und seinen Umfang entsprechend schriftlich erklärt.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.6</strong> Korrespondenz: Soweit nichts anderes vereinbart ist, können Sie als Mitglied oder Nutzer der Internetseiten alle Erklärungen an den German Emirates Club per E-Mail mit dem von den Internetseiten des German Emirates Club aus erreichbaren Kontaktformular, der German Emirates Club kann Erklärungen gegenüber Ihnen per E-Mail an diejenige Adresse übermitteln, die das Mitglied als aktuelle Kontaktadresse in seiner Anmeldung bzw. der Nutzer im Kontaktformular angegeben hat, wirksam abgeben</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.7</strong> Force Majeure: Der German Emirates Club kann unter keinen Umständen haftbar gemacht werden für Verzögerungen oder Nichtleistungen, die mittel- oder unmittelbar auf Naturgewalten, höhere Gewalt oder unkontrollierbaren Umstände beruhen. Dazu gehören unter anderem jedoch nicht ausschließlich folgende Gründe: Ausfälle von Internet, Computer- Ausstattung, Software, Telekommunikationsausrüstung, Ausrüstungen aller Art, Stromausfälle, Streik, Arbeitsstreit, Aufstände, Aufruhr, gesellschaftliche Unruhen, Mangel an Material oder Arbeitskräften, Feuer, Überschwemmungen, Stürme, Explosionen, Naturgewalten, politische Handlungen, Krieg, Anweisungen nationaler oder internationaler Gerichte, Nichtleistung Dritter, Verluste oder Schwankungen infolge von Hitze, Licht oder Klimaanlagen.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.8</strong> Salvatorische Klausel: Sollten einzelne Regelungen dieser ACB unwirksam oder nicht durchsetzbar sein oder werden, wird dadurch die Wirksamkeit oder Durchsetzbarkeit der übrigen Regelungen nicht berührt. Die Vertragspartner verpflichten sich, eine unwirksame/nicht durchsetzbare Regelung durch eine solche wirksame/durchsetzbare Regelung zu ersetzen, die in ihrem Regelungsgehalt dem wirtschaftlich gewollten Sinn und Zweck der unwirksamen Regelung möglichst nahe kommt. Das gilt entsprechend bei Vertragslücken.</NormalTexts>
          <NormalTexts textAlign="justify" mb={2}><strong>8.9</strong> Anwendbares Recht und Gerichtsstand: Diesen ACB liegt das Recht der Vereinigten Arabischen Emirate zu Grunde. Sie behalten ihre Wirksamkeit auch im Falle anders lautender gesetzlicher Bestimmungen des Aufenthaltsortes des Mitglieds oder Nutzers der Internetseiten des German Emirates Club. Erfüllungsort ist der Sitz des German Emirates Club. Die Parteien stimmen überein, dass der ausschließliche Gerichtsstandort die Vereinigten Arabischen Emirate sind.</NormalTexts>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"

          >
            {" "}
                {showGoTop && (
                <Fab variant="extended" sx={{ position: "fixed", bottom:20, pr:2 }} onClick={ goToTop }>
                  <Navigation sx={{ mr: 1 }}/> nach oben
                </Fab>
            )}{" "}

            
          </Grid>
          
        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Allgemeine Clubbedingungen</title>
        <meta name="Title" content="German Emirates Club | Allgemeine Clubbedingungen" />
        <meta name="robots" content="noindex,follow"/>
      </Helmet>

    </Box>
  )
};

export default Terms;
