import { Box, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Stack, Typography } from '@mui/material';
import Homelogin from '../../components/HomeLogin';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import QuoteImage from '../../assets/__icn_cite.gif'
import styled from "styled-components";
import { Helmet } from 'react-helmet';

interface HomepageProps {}
const Cards = [
  {
    title: 'Mitgliedschaft',
    content: 'Mitgliedschaft setzt Vertrauen voraus. Deshalb brauchen Sie eine Einladung in den German Emirates Club von jemandem, der schon Mitglied ist.',
    banner: require('../../assets/__img_membership_2.jpeg'),
    link: '/Membership'
  },
  {
    title: 'Marktplatz',
    content: 'Der Marktplatz dient zum privaten Kauf, Verkauf und Tauschen von Artikeln sowie für Jobangebote, Jobgesuche und Schnäppchen.',
    banner: require('../../assets/__img_marketplace_2.jpeg'),
    link: '/Marketplace'   
  },
  {
    title: 'Partner',
    content: 'Die Partner des German Emirates Clubs bieten attraktive Angebote und Sonderkonditionen für alle Mitglieder mit Mitgliedskarte.',
    banner: require('../../assets/__img_partner.jpeg'),
    link: '/Partner'
  },
  {
    title: 'Forum',
    content: 'Das Forum wurde geschaffen, um Fragen zu stellen und Hilfe, Insider-Tipps und Hintergrund-informationen von anderen Mitgliedern zu erhalten.',
    banner: require('../../assets/__img_forum.jpeg'),
    link: '/Forum'
  },
  {
    title: 'Ausschüsse',
    content: 'Durch die Ausschüsse bietet der Club qualifizierte Hilfestellung und Kontakte für unterschiedliche Anliegen und informiert über aktuelle Fragen.',
    banner: require('../../assets/__img_committees.jpeg'),
    link: '/Committees'
  },
  {
    title: 'Event',
    content: 'Der Club veranstaltet diverse Events. Sie sollen dazu dienen, sich besser kennen zu lernen oder die Geschäftsbeziehungen zu vertiefen.',
    banner: require('../../assets/__img_events.jpeg'),
    link: '/Events/Gallery'
  }
]

const fontFam = 'Century Gothic,CenturyGothic,AppleGothic,sans-serif';
const STypography = styled(Typography)(({ theme }) => ({
  '&': {
    fontfamily: {fontFam},
    color: '#436885'
  }
})) as typeof Typography

const Homepage  = () => {

  useEffect( () => {
    window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } );
    console.log('trigger scroll')
  }, [] );

  return  (
    <Box sx={{ background: "linear-gradient(353deg, rgba(211,221,229,1) 0%, rgba(228,233,237,1) 35%, rgba(255,255,255,1) 100%)" }}>
      <Homelogin />
      <Container sx={{ mX:0, pX:0, maxWidth:1274, position: "relative" }} >


        <Box sx={{ display: { xs: 'flex', md: 'none' }, mt:4 }}>
          <Box flex={1}  position='relative'>
            <Box component="img" src={QuoteImage} sx={{ position:'absolute', left:0, top:0 }} />
            <STypography mb={2}  fontWeight={400}  fontSize={24} textAlign="right" color="#fe7b00" pr={2} >
              Willkommen
            </STypography>
            <STypography my={4}   fontWeight={100} fontSize={16} textAlign="left" fontStyle="italic" pr={2} sx={{ lineHeight: '30px' }}>
                Der German Emirates Club ist der exklusive Club aller deutschsprachigen Expats, mit einem Lebensmittelpunkt in den Vereinigten Arabischen Emiraten.
            </STypography>
            <STypography mt={4}  fontWeight={100}  fontSize={16} textAlign="left" fontStyle="italic" pr={2} sx={{lineHeight: '30px'}}>
              Netzwerke werden immer wichtiger, dies gilt insbesondere im Ausland. Für unsere Clubmitglieder, die bereits Verbindungen miteinander haben, hat der Club diese Internetseite eingerichtet, die zur Stärkung und besseren Vernetzung der Clubmitglieder untereinander dienen soll.
            </STypography>
            <Box textAlign='right' >
              <Box component="img" src={QuoteImage} sx={{ right:0, bottom:0, position: 'relative' }} flex={1}/>
            </Box>
          </Box>
        </Box> 

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          py={4}
        >
          <Box flex={2}  sx={{ display: { xs: 'none', md: 'flex' }, pl: 0, ml:0}}>
            <Box flex={1}  pr={2} position='relative'>
              <Box component="img" src={QuoteImage} sx={{ position:'absolute', left:0, top:0 }} />
              <STypography mb={2}  fontWeight={400}  fontSize={24} textAlign="right" color="#fe7b00" pr={2} >
                Willkommen
              </STypography>
              <STypography my={4}   fontWeight={100} fontSize={16} textAlign="left" fontStyle="italic" pr={2} sx={{ lineHeight: '30px' }}>
                  Der German Emirates Club ist der exklusive Club aller deutschsprachigen Expats, mit einem Lebensmittelpunkt in den Vereinigten Arabischen Emiraten.
              </STypography>
              <STypography mt={4}  fontWeight={100}  fontSize={16} textAlign="left" fontStyle="italic" pr={2} sx={{lineHeight: '30px'}}>
                Netzwerke werden immer wichtiger, dies gilt insbesondere im Ausland. Für unsere Clubmitglieder, die bereits Verbindungen miteinander haben, hat der Club diese Internetseite eingerichtet, die zur Stärkung und besseren Vernetzung der Clubmitglieder untereinander dienen soll.
              </STypography>
              <Box textAlign='right' pr={'20px'}>
                <Box component="img" src={QuoteImage} sx={{ right:0, bottom:0, position: 'relative' }} flex={1}/>
              </Box>
            </Box>
          </Box> 

          <Box flex={5} id={'home-cards'}> 
            <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
              {Cards.map((__, index) => (
                <Grid item xs={4} sm={4} md={4} key={index}>
                  <Link to={__.link} style={{textDecoration: 'none'}}>
                    <Card elevation={0} sx={{ 
                      height: {xs:280, sm: 320},
                      boxShadow: '0px -5px 6px #00000029',
                      border: '1px solid #707070',
                      borderRadius: '10px',
                      p:'8px',
                      background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(227,232,237,1) 51%, rgba(255,255,255,1) 100%)'
                    }} >
                      <STypography sx={{
                        fontWeight:600,
                        fontfamily: {fontFam},
                      }}>
                        {__.title}
                      </STypography>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={__.banner}
                          alt={__.title}
                        />
                        <CardContent sx={{
                          py:'8px', 
                          px:0
                        }}>
                          <STypography sx={{
                            fontfamily: {fontFam},
                            
                          }}>
                          {__.content}
                          </STypography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>


        </Stack>
      </Container>

      <Helmet>
        <title>German Emirates Club</title>
        <meta name="robots" content="index, follow"/>
        <meta name="Title" content="German Emirates Club"/>
        <meta name="Keywords" content="gec, German Emirates Club, Club, Kontakte, Vereinigte Arabische Emirate, Ausland, Expats, german emirates club"/>
        <meta name="Description" content="Der German Emirates Club ist der exklusive Club aller deutschsprachigen Expats, mit einem Lebensmittelpunkt in den Vereinigten Arabischen Emiraten."/>
      </Helmet>

    </Box>
  )
}

export default Homepage;
