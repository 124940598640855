import React, { useState, useEffect, useCallback } from "react";
import { alpha, styled,InputBase, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, OutlinedInput, useTheme, Theme, Alert } from '@mui/material';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Link, useNavigate } from "react-router-dom";
import { ChevronRight} from '@mui/icons-material';
import { GoogleReCaptchaProvider,  useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { maxWidth } from "@mui/system";
import axios from "axios";

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}
const SInputLabel = styled(InputLabel)(({ theme }) => ({
  '&': {
    color: '#436885',
    fontSize:20,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof InputLabel

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    marginBottom: theme.spacing(3),
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const fontFam = 'Century Gothic,CenturyGothic,AppleGothic,sans-serif';

const Reset = () => {

  const navigate = useNavigate();

  const [formError, SetFormError] = useState('');
  const defData = {
    username_email: { value: "",  error: "" }
  }
  const [formData, setFormData] = useState(defData);
  const handleChange = (e : any) => {
    const isError = !e.target.value;
    let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : "";
    setFormData(prev => ({ ...prev, [e.target.name]: {  value: e.target.value, error: errMsg  }}));
  }

  const [formSuccess, SetFormSuccess] = useState('');

  const handleSubmit = async (e : any) => {
    try {
      SetFormError('');
      SetFormSuccess('');
      e.preventDefault();

      // validate inputs first
      let hasError = false;
      Object.keys(formData).forEach(field => {
        let isError = !formData[field].value;
        let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : '';
        if(errMsg != '') hasError = true;
        setFormData(prev => ({ ...prev, [field]: { ...formData[field], error: errMsg } }));
      });
      if(hasError) return;

      const reqData = {
        username_email: formData.username_email.value
      }
      console.log('reqData', reqData);
      const res = await axios.post('reset.php', reqData); 
      console.log("res: ", res);
      if(res.data.status > 0 && res.data.msg) {
        //navigate('/');
        //res.data.msg
        SetFormError(res.data.msg);
      } else if(res.data.msg){
        SetFormSuccess(res.data.msg);
      }
    } catch (error) {
      console.log(error)
      SetFormError('Etwas ist schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.');
    }

  };


  return (

    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
        <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
          <Paper sx={{ p: 5}} elevation={20} >

            <TitleTexts mb={2} ><span style={{ color:'#001b39'}}>Logindaten anfordern</span></TitleTexts>
            <Divider sx={{mb:3}} />

            <Box>
              <List sx={{ width: '100%'}} >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon> <ChevronRight /> </ListItemIcon>
                    <ListItemText primary={  <ListTexts> Geben Sie in das Feld Ihren Benutzernamen oder Ihre Emailadresse ein, die Sie bei Ihrer Registrierung angegeben haben</ListTexts>  } />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>

            <Grid container spacing={2} columns={{ xs: 4, sm:12, md: 12 }}>
              <Grid item xs={4} sm={6} md={6} >
                <FormControl variant="standard" sx={{width:'100%'}}>
                  <SInputLabel shrink htmlFor="name-input">
                  Benutzername/Emailadresse * <span style={{ marginLeft:5, color: "red" }} > {formData.username_email.error}</span>
                  </SInputLabel>
                  <BootstrapInput id="username_email-input" name="username_email" onChange={handleChange}  required value={formData.username_email.value} />
                </FormControl> 

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={4} justifyContent='start'>
                    <Button onClick={handleSubmit} variant="contained" sx={{
                       background: 'linear-gradient(106deg, rgba(255,196,94,1) 0%, rgba(255,113,11,1) 100%)',
                      borderRadius: 8,
                      px: 6,
                      textTransform: 'none',
                      mb:1,
                      fontSize:'16px',
                      fontFamily:{fontFam}
                    }}>Absenden</Button>
                  </Grid>
                  <Grid item xs={8} justifyContent='start'>
                    <Button onClick={()=>{ navigate('/')}} variant="contained" sx={{
                      background: 'linear-gradient(106deg, rgba(239,239,239,1) 0%, rgba(170,170,170,1) 100%)',
                      borderRadius: 8,
                      px: 6,
                      textTransform: 'none',
                      mb:1,
                      fontSize:'16px',
                      fontFamily:{fontFam}
                    }}>Abbrechen</Button>
                  </Grid>

                </Grid>
                  </Grid>
                  <Grid item xs={4} sm={6} md={6} ></Grid>
            </Grid>

            { formError ? <Alert severity="error" sx={{mt:1}}>{formError}</Alert> : '' }       
            { formSuccess ? <Alert severity="success" sx={{mt:1}}>{formSuccess}</Alert> : '' }       

          </Paper>
        </Container>
    </Box>

  )
}

export default Reset;
