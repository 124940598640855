import React, { useRef, useState, useEffect } from 'react';
import { styled, Avatar, AppBar, Toolbar, Typography, Badge, Box, IconButton } from '@mui/material';
import { Mail, Notifications, Apps, More, Person, Settings, Logout, Home, Monitor, Forum, Storefront, Lan, ConnectWithoutContact, Feed, CalendarMonth, ContactPage, HelpCenter,  } from '@mui/icons-material';
import GECLogo from '../../assets/__local_logo.jpeg';
import { TieredMenu } from 'primereact/tieredmenu';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../redux/userSlice";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";


const StyledTypo= styled(Typography)(({ theme }) => ({
  '&': {
    fontFamily: "Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    fontSize: 20,
    color: '#001b39'
  },
})) as typeof Typography

const Header = () => {

  const { currentUser } = useSelector((state:any) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const mobileMenu = useRef<TieredMenu>(null);
  const mobileMenuItems = [
        {
            label:'Messages',
            icon:
              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="error">
                  <Mail/>
                </Badge>
              </IconButton>,
            url: '/'
        },
        {
          label:'Notifications',
          icon:
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <Notifications />
            </Badge>
          </IconButton> ,
          url: '/'
        },
        {
          label:'Profile',
          icon:
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Avatar sx={{ height:24, width: 24 }} alt="" src="https://mui.com/static/images/avatar/2.jpg" />
          </IconButton>,
          items:[
              {
                  label:'Hilfe',
                  icon:'pi pi-fw pi-align-left'
              },
              {
                  label:'Kontakt',
                  icon:'pi pi-fw pi-align-right'
              },
          ]
        }
      ];

  const profileMenu = useRef<TieredMenu>(null);
  const profileMenuItems = [
    {
      label: 'Profile',
      icon: <Person />,
      command: (event: any) => {
        navigate('/Membership')
      }
    },
    {
      label: 'My Account',
      icon: <Person />,
      command: (event: any) => {
        navigate('/Membership')
      }
    },
    {
      separator:true
    },
    {
      label: 'Settings',
      icon: <Settings />,
      command: (event: any) => {
        navigate('/Membership')
      }
    },
    {
      label: 'Logout',
      icon: <Logout/>,
      command: ()=> { dispatch(logout());  navigate('/'); }
    }
    
  ];

  const mainMenu = useRef<TieredMenu>(null);
  const toggleMainMenu = (event) =>  {
    if (mainMenu && mainMenu.current) {
      mainMenu.current.toggle(event);
    }
  }

  const mainMenuItems = [
        {
          label: 'Start',
          icon: <Home sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/')
          }
        },
        {
            separator:true
        },
        {
          label: 'Mitgliedschaft',
          icon: <Monitor sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Membership')
          }
        },
        {
          label: 'Forum',
          icon: <Forum sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Forum')
          }
        },
        {
          label: 'Marktplatz',
          icon: <Storefront sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Marketplace')
          }
        },
        {
          label: 'Ausschüsse',
          icon: <Lan sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Committees')
          }
        },
        {
          label: 'Partner',
          icon: <ConnectWithoutContact sx={{ mr:1}} />,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Partner')
          }
        },
        {
          label: 'Magazin',
          icon: <Feed sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Magazine')
          }
        },
        {
          label: 'Events',
          icon: <CalendarMonth sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Events/Gallery')
          }
        },
        {
          label: 'Kontakt',
          icon: <ContactPage sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            // navigate('/Contact')
            window.location.replace('https://www.german-emirates-club.com/Contact')
          }
        },
        {
            separator:true
        },
        {
          label: 'Hilfe',
          icon: <HelpCenter sx={{ mr:1}}/>,
          command: (event: any) => {
            toggleMainMenu(event);
            navigate('/Help')
          }
        }
      
    ];


  return (


    <Box sx={{ flexGrow: 1, bgcolor: "white", marginY: 0, paddingY: 0 }} m={0} p={0} position="fixed" top={0} width='100%' height={50} zIndex={1000}>
      <AppBar position="relative" sx={{ bgcolor: "white",  color: '#001b39', maxWidth: 1274, maxHeight:50, mx:'auto', px:{xs:1, sm:2, md:2, lg: 0} }} elevation={0}>

          <Toolbar sx={{ padding: 0}}>

            <Link to="/"  >
              <Box component="img" sx={{ height: 42, mr: 2  }} alt="GEC Logo"  src={GECLogo} />
            </Link>

            <StyledTypo sx={{  display: { xs: 'none', md: 'flex' } }} >
              Willkommen in der Welt des
            </StyledTypo>
            <Typography fontFamily='Trajan Pro Bold' color='#001b39' sx={{ fontWeight:600 , fontSize:{xs:18,sm:20}, mt:{xs:0,sm:'6px',md:'6px'}, ml:{xs:0,sm:'6px',md:'6px'} }}  >
              German Emirates Club
            </Typography>


            <Box sx={{ flexGrow: 1 }} />
            {  currentUser ? 
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="error">
                  <Mail />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <Notifications />
                </Badge>
              </IconButton>
              <IconButton
                size="small"
                aria-label="account of current user"
                onClick={(event) => { if (profileMenu && profileMenu.current) profileMenu.current.toggle(event)}}
                color="inherit"
                edge="end"
              >
                <Avatar sx={{ height:24, width: 24 }} alt="" src="https://mui.com/static/images/avatar/2.jpg" />
              </IconButton>
            </Box> :'' }
            {  currentUser ? 
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                onClick={(event) => { if (mobileMenu && mobileMenu.current) mobileMenu.current.toggle(event)}}
                color="inherit"
              >
                <More />
              </IconButton>
            </Box> : '' }

            <Box marginRight={{xs:'-8px', sm:'-8px',md:'-15px'}}>
              <IconButton
                size="large"
                aria-label="show more"
                onClick={(event) => { if (mainMenu && mainMenu.current) mainMenu.current.toggle(event)}}
                color="inherit"
              >
                <Apps />
              </IconButton>
            </Box>

          </Toolbar>
      </AppBar>
      <TieredMenu model={mainMenuItems} popup ref={mainMenu} id="overlay_tmenu"/>
      <TieredMenu model={profileMenuItems} popup ref={profileMenu} id="overlay_tmenu" />
      <TieredMenu model={mobileMenuItems} popup ref={mobileMenu} id="overlay_tmenu" />
    </Box>
  );
}

export default  Header;