import React, { useState, useEffect, useCallback } from "react";
import { alpha, styled,InputBase, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, ListItemButton, ListItemIcon, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, OutlinedInput, useTheme, Theme } from '@mui/material';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Helmet } from 'react-helmet';
import CorporateCardBG from '../../assets/CorporateCardBG.png';
import CorporateCard from '../../assets/CorporateCard.png';
import HighResFamily from '../../assets/HighResFamily.jpg';
import { Stack } from "@mui/system";
import { relative } from "path";
import GooglePlay from '../../assets/GooglePlay.png';
import AppStore from '../../assets/AppStore.png';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:16,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:13,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography

const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}



const Corporate  = () => {

 
  return (
    <Box sx={{ 
      background: '#fff',
      minHeight:'calc(100vh - 150px) ',
      width: '100%',
      height: '100%',
      pt:'100px',
      pb:'150px',
    }}>

      <Box sx={{
        display: 'block',
        position:'relative',
      }}>

        <Box position={'absolute'}  width={'100%'} height={'450px'} overflow={'hidden'} >
          <Grid container spacing={0} columns={{ xs: 4, md: 18 }} height={'100%'}>
            <Grid item xs={4} md={10} position={'relative'} sx={{
              // backgroundImage: `url(${HighResFamily})`,
            }}>
              <Box component='img' src={HighResFamily} width={'100%'} position='absolute' bottom={0} />
            </Grid>
            <Grid item xs={4} md={8} > </Grid>
          </Grid>
        </Box>

        <Box position={'absolute'}  width={'100%'} height={'450px'} overflow={'hidden'} >
          <Box component='img' src={CorporateCardBG} width={'100%'}   sx={{opacity: 0.6}}  />
        </Box>
            
        <Box position={'absolute'}  maxWidth={1274} width={'100%'} left={'50%'} textAlign={'center'}>
          <Grid container spacing={0} columns={{ xs: 4, md: 18 }} position={'relative'} left={'-50%'}>
            <Grid item xs={4} md={11} >
              {/* <Box component='img' src={HighResFamily} width={'100%'} position='relative' left={0}  bottom={0} /> */}
            </Grid>
            <Grid item xs={4} md={7} justifyContent={'center'}>
              <Box component='img' src={CorporateCard} width={'300px'} sx={{ transform:'rotate(-15deg)' }}/>
              <Box textAlign={'center'} px={'10px'} my={'60px'}>
                <NormalTexts>The Corporate Card is an initiative of the German, Austrian, and Swiss business society in the UAE to provide discounts and privileges to their employees.</NormalTexts>
                <NormalTexts mt={'20px'}>Get  your app now and enjoy your discounts and privileges.</NormalTexts>
              </Box> 
            </Grid>
          </Grid>
        </Box>
            
        <Box position={'absolute'} top={0}  width={'50%'} pt={'408px'}  >
          <Grid container spacing={{xs:2,sm:4}} columns={{ xs:4,sm:4 }} direction="row" justifyContent="center" alignItems="center" >
              <Grid item xs={4}  sm={2} textAlign={{xs:'center',sm:'end'}} >
                <a href="https://play.google.com/store/apps/details?id=com.buenapublica.GECRewards" target="_blank">
                  <Box component='img' src={GooglePlay} maxHeight={'80px'} maxWidth={{xs:'200px',sm:'100%'}} /> 
                </a>
              </Grid>
              <Grid item xs={4} sm={2} textAlign={{xs:'center',sm:'start'}} >
                <a href="https://apps.apple.com/ae/app/gec-rewards/id6444924851" target="_blank">
                  <Box component='img' src={AppStore} maxHeight={'80px'} maxWidth={{xs:'200px',sm:'100%'}} /> 
                </a>
              </Grid>
          </Grid>
        </Box>
   


        </Box>


        <Helmet>
          <title>German Emirates Club | Coporate Card Holders</title>
          <meta name="Title" content="German Emirates Club | Coporate Card Holders"/>
          <meta name="robots" content="noindex,follow"/>
        </Helmet>

    </Box>

  )
}

export default Corporate;
