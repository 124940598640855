import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Grid, Typography, List, ListItem, Divider, ListItemText, Toolbar, ListItemButton, ListItemIcon } from '@mui/material';
import { ChevronRight} from '@mui/icons-material';
import ForumBanner from '../../assets/__img_forum_2.jpg';
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const LinkStyled =  {
  fontSize:20,
  color: '#436885',
  fontWeight: 500,
  display: 'inline',
  fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
}

const Forum  = () => {
return (
  <Box position="relative" sx={{ 
    backgroundColor: 'rgba(255, 255, 255, 0.32)',
    minHeight:'calc(100vh - 150px) ',
  }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >
          <Grid container spacing={4} columns={{ xs: 4, sm:12, md: 12 }}>
       
              <Grid item xs={4} sm={6} md={7} >
                <Box>
                    <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Forum</span></TitleTexts>
                    <Divider sx={{mb:3}} />
                    <NormalTexts mb={2}>Sich in den Emiraten ein neues Leben aufzubauen kann Monate in Anspruch nehmen, sich "zu Hause" zu fühlen sogar noch länger. Dabei sind die Bedürfnisse derer, die neu in die Emirate kommen ähnlich derer die bereits vor Ort leben.</NormalTexts>
                    <NormalTexts mb={2}>So bietet der German Emirates Club ein Forum, damit die Mitglieder sich austauschen können. Es besitzt umfangreiche Funktionen, die aber äußerst leicht zu bedienen sind. Im Forum finden Sie Antworten in den Bereichen Kinder und Familie, Shopping, Sport, Wohnen, Kultur etc. und unter dem Bereich „Neu im Lande“ finden Sie viele Antworten auf Ihre Fragen wie z.B.:</NormalTexts>

                    <List sx={{ width: '100%'}} >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> In welchem Stadtviertel lebt es sich am besten? </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> Wo bekomme ich qualifizierte medizinische Hilfe oder den richtigen rechtlichen Beistand? </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> Wo kann ich meinen Sport ausüben oder meine Hobbys pflegen? </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> Wo kann ich Kontakte knüpfen und Freunde kennen lernen? </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> An wen wende ich mich im Notfall? </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> Was mache ich mit meinem Hausstand wenn ich das Land wieder verlasse? </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> Unter „Le Café“ können Sie Fragen stellen zu allgemeinen Themenbereichen, Antworten geben sowie diskutieren, mitreden und auch schwärmen. </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon> <ChevronRight /> </ListItemIcon>
                          <ListItemText primary={  <ListTexts> Sie finden im Forum News, Hintergrundinformationen und Insider-Tipps. </ListTexts>  } />
                        </ListItemButton>
                      </ListItem>
              
                    </List>
             
                </Box>
              </Grid>

              <Grid item xs={4} sm={6} md={5} >
 
                    <Box component='img' src={ForumBanner} width='100%' />

                    <Toolbar sx={{ backgroundColor:'#efefef', minHeight:'unset', px:1, py:0, mt:2}}>
                      <Typography variant="h6" pl={2} fontWeight={600}> Kurz und knapp </Typography>
                    </Toolbar>
                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Der GEC bietet ein Forum </ListTexts> }
                          secondary={
                            <ListTexts>
                              für Familie, Reisen, Shopping, Business und Kultur, u.v.m.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Das Forum bietet umfangreiche Funktionen </ListTexts> }
                          secondary={ 
                            <ListTexts>
                              und ist dennoch einfach zu bedienen.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Im "Le Cafe" kostenlose Fragen stellen</ListTexts> }
                          secondary={
                            <ListTexts>
                              und Antworten geben, Diskutieren, Mitreden und Schwärmen.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={ <ListTexts fontWeight={600}> Vorschläge, Hilfe und Unterstüt  </ListTexts> }
                          secondary={
                            <ListTexts>
                              sowie Insider-Tipps für Newbies.
                            </ListTexts>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                    </List>
            
              </Grid>

            </Grid>
        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Forum</title>
        <meta name="robots" content="index, follow"/>
        <meta name="Title" content="German Emirates Club | Forum"/>
      </Helmet>
  </Box>
)
}

export default Forum;
