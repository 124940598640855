import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Grid, Typography, Divider, Button, Skeleton, Pagination } from '@mui/material';
import {ArrowBack, KeyboardDoubleArrowRight} from '@mui/icons-material';
import LoginModal from '../../components/LoginModal';
import parser from 'bbcode-to-react';
import axios from "axios";
import { Helmet } from 'react-helmet';
import { useSearchParams, useLocation, useParams, Link, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ImageBox = styled(Box)(({ theme }) => ({
  '&': {
    borderRadius: 1,
    padding: 1,
    marginX:'auto',
    width: '100%'
  },
})) as typeof Box

const prefUrl = '/uploads/sys/';

const MagazineArticle = () => {


  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const navigate = useNavigate();

  const locationState = useLocation().state as {article: any}; 
  const magazinArticle= locationState.article;
  console.log(magazinArticle);

  //const [pageParams, setpageParams] = useState(new URLSearchParams(search).get('page'));
  const pageParams = new URLSearchParams(search).get('page');
  const [Page, setPage] = useState(pageParams ? parseInt(pageParams) : 1);
  const [Count, setCount] = useState(0);
  const [magazine, setMagazine] = useState(magazinArticle);
  const Limit = 5;
  const prefUrl = '/uploads/sys/';

  const fetchMagazine = async () => { 
    console.log('id', id)
    const res = await axios.post('./magazin.php', { 
      limit: Limit,
      page: Page,
      id: id
    });
    setMagazine(res.data.article);
    let limitCount = res.data.limit ? Math.ceil(res.data.limit.count/Limit) : 0;
    setCount(limitCount);

    console.log('res.data', res.data)
    
  }

  useEffect(()=> {
    console.log('pageParams', pageParams)
    console.log('Page', Page)
    if(!magazinArticle) {
      fetchMagazine();
    }
  },[])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setMagazine([]);
    setPage(value);
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', value.toString());
    setSearchParams(updatedSearchParams.toString());
    fetchMagazine();
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
        <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
          <Paper sx={{ p: 5}} elevation={20} >

           
            <Grid container spacing={1} columns={{ xs: 12 }}  mb={2} >
              <Grid item xs={8} > <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Magazin</span></TitleTexts></Grid>
              <Grid item xs={4} justifyContent='flex-end' justifyItems='flex-end' sx={{ textAlign:'right'}} alignItems='center'>
                  <Typography  pt={1} fontSize={20}> 
                  <a onClick={ () => { navigate('/Magazine')}} > <ArrowBack sx={{height:'20px', paddingTop:'4px'}} /> Zurück zur Übersicht </a></Typography> </Grid>
            </Grid>

            <Divider sx={{mb:3}} />

            { 
              magazine.length ?
              magazine.map((mag:any) => {
              // let imgPath = mag.image ? `${prefUrl}${mag.image.file}` : '';
              let imgPath = mag.short_desc.img ? `${prefUrl}${mag.short_desc.img}` : '';
              return (<Box key={mag.id}>
                {/* { mag.image || mag.video ?  */}
                { mag.short_desc.img || mag.short_desc.vid ? 
                <Grid container spacing={2} columns={{ xs: 4, sm: 12 }}>
                    <Grid item xs={4} sm={3} justifyContent='center' >
                      <ImageBox component='img' src={imgPath} mr={4} border='1px solid #CCC' loading="lazy" /> 
                    </Grid>
                    <Grid item xs={4} sm={9} >
                      <ListTexts fontWeight={600} fontSize={18} mb={2}><Link style={{color:'rgb(67, 104, 133)', textDecoration:'none'}} to={mag.href}>{mag.title}</Link></ListTexts>
                      <NormalTexts>
                        {parse(mag.short_desc.short)}
                      </NormalTexts>
                    </Grid>
                    <Box sx={{width:'100%', textAlign:'right'}} ><a style={{fontWeight:600, fontSize:15}} onClick={() => handleOpen()} >Weiterlesen »</a></Box>
                </Grid>
                : ''}
                {/* { !mag.image && !mag.video ?  */}
                { !mag.short_desc.img && !mag.short_desc.vid ? 
                <Grid container spacing={2} columns={{ xs: 4 }}>
                    <Grid item xs={4}  >
                      <ListTexts fontWeight={600} fontSize={18} mb={2}><Link style={{color:'rgb(67, 104, 133)', textDecoration:'none'}} to={mag.href}>{mag.title}</Link></ListTexts>
                      <NormalTexts>
                        {parse(mag.short_desc.short)}
                      </NormalTexts>
                    </Grid>
                    <Box sx={{width:'100%', textAlign:'right'}} ><a style={{fontWeight:600, fontSize:15}} onClick={() => handleOpen()} >Weiterlesen »</a></Box>
                </Grid>
                : ''}
                
                <Divider sx={{m:2}} />
              </Box>
              )})
              :
              Array.from(new Array(9)).map((i) => {
                return (
                  <Box key={i} sx={{ position: 'relative'}}>
                    <Grid container spacing={2} columns={{ xs: 4, sm: 12 }} >
                        <Grid item xs={4} sm={3} justifyContent='center' >
                          <Skeleton variant="rectangular"  height={200} width={'100%'} />
                        </Grid>
                        <Grid item xs={4} sm={9} >
                          <ListTexts fontWeight={600} fontSize={18} mb={2}> <Skeleton width="40%" /></ListTexts>
                          <Box sx={{ whiteSpace:'pre-wrap'}}>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton /> 
                            <Skeleton width="90%" />
                          </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{m:2}} />
                  </Box>
                )})
            }
            {
              Count > 1 ? 
                <Box textAlign={'center'} maxWidth={'355px'} mx={'auto'}>
                  <Pagination count={Count} page={Page} onChange={handleChange}  sx={{mx:'auto', textAlign:'center'}}/>
                </Box> : ''
            }

          </Paper>
        </Container>
        <LoginModal
          isDialogOpened={isOpen}
          handleCloseDialog={() => setIsOpen(false)}
        />

      <Helmet>
        <title>German Emirates Club | Magazine</title>
        <meta name="Title" content="German Emirates Club | Magazine"/>
        <meta name="Keywords" content="Magazin, Artikel, Nachrichten, News, Neuigkeiten, Berichte"/>
        <meta name="Description" content="Magazin, Artikel, Nachrichten, News, Neuigkeiten, Berichte"/>
        <meta name="page-topic" content="Magazin, Artikel, Nachrichten, News, Neuigkeiten, Berichte"/>
        <meta name="revisit-after" content="1 day"/>
      </Helmet>
    </Box>
  )
}

export default MagazineArticle;
