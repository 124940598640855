import * as React from 'react';
import {
  ThemeProvider,
  createTheme,
  experimental_sx as sx,
} from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          padding: 'initial',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Century Gothic, sans-serif',
    h1: {
      fontSize: '23',
    },
    body1: {
      color: 'red'
    }
  },
});