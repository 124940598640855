
import React from 'react';
import { Box , Stack} from '@mui/material';
import Feed from '../../components/Feed'
import Sidebar from "../../components/Sidebar";

interface MemberProps {}

const Member = () => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="stretch"
    spacing={2}
    p={2}
  >
    <Sidebar></Sidebar>
    <Box flex={5}> 
      <Stack spacing={2}>
        <Feed />
        <Feed />
      </Stack>
    </Box>
  </Stack>
)
export default Member;
