import React, { FC } from 'react';


interface EventsProps {}

const Events: FC<EventsProps> = () => (
  <div>
    Events Component
  </div>
);

export default Events;
