import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Grid, Typography, Divider, Skeleton } from '@mui/material';
import axios from "axios";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ImageBox = styled(Box)(({ theme }) => ({
  '&': {
    borderRadius: 1,
    padding: 1,
    marginX:'auto',
    width: '100%'
  },
})) as typeof Box

const Committees  = () => {
  const [committees, setCommittees] = useState([]);
  const prefUrl = 'https://www.german-emirates-club.com/uploads/sys/';

  useEffect(()=> {
    const fetchCommittees =async () => {
      const res = await axios.get('https://www.german-emirates-club.com/api/api/committees/get-all');
      setCommittees(res.data.data);
    }
    fetchCommittees();
  },[])


  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
        <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
          <Paper sx={{ p: 5}} elevation={20} >

            <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Ausschüsse</span></TitleTexts>

            <Divider sx={{mb:3}} />
            {
              committees.length ? 
              committees.map((comm:any) => {
                let imgPath = `${prefUrl}${comm.file}`;
                return (<Box sx={{ position: 'relative'}} key={comm.id}>
                  <Grid container spacing={2} columns={{ xs: 4, sm: 12 }} key={comm.id} >
                      <Grid item xs={4} sm={4} justifyContent='center' key={`${comm.id}1`} >
                        <ImageBox component='img' src={imgPath} mr={4} border='1px solid #CCC' />
                      </Grid>
                      <Grid item xs={4} sm={8} key={`${comm.id}2`}>
                        <ListTexts fontWeight={600} fontSize={20} mb={2}> {comm.title}</ListTexts>
                        <Box sx={{ 
                          whiteSpace:'pre-wrap',
                          fontSize:20,
                          color: '#000',
                          fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
                        }}>
                          <ReactReadMoreReadLess
                            charLimit={460}
                            readMoreText={"Weiterlesen »"}
                            readLessText={""}
                          >
                  
                              {comm.body} 
                    
                          </ReactReadMoreReadLess>
                        
                        </Box>
                      </Grid>
                  </Grid>
                  <Divider sx={{m:2}} />
                </Box>
              )})
              :
              Array.from(new Array(9)).map((i) => {
              return (
                <Box key={i} sx={{ position: 'relative'}}>
                  <Grid container spacing={2} columns={{ xs: 4, sm: 12 }} >
                      <Grid item xs={4} sm={4} justifyContent='center' >
                        <Skeleton variant="rectangular"  height={230}/>
                      </Grid>
                      <Grid item xs={4} sm={8} >
                        <ListTexts fontWeight={600} fontSize={18} mb={2}> <Skeleton width="40%" /></ListTexts>
                        <Box sx={{ whiteSpace:'pre-wrap'}}>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /> 
                          <Skeleton width="90%" />
                        </Box>
                      </Grid>
                  </Grid>
                  <Divider sx={{m:2}} />
                </Box>
              )})
            }

          </Paper>
        </Container>

        <Helmet>
          <title>German Emirates Club | Ausschüsse</title>
          <meta name="robots" content="index, follow"/>
          <meta name="Title" content="German Emirates Club | Ausschüsse"/>
        </Helmet>

    </Box>
  )
}

export default Committees;
