import React, { useState } from 'react';
import PrimeReact from 'primereact/api';
import { styled, Box, Container } from "@mui/material";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/Theme";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from './components/Footer';

import Member from "./pages/Member";
import Login from "./pages/Login";
import Homepage from './pages/Homepage';
import Membership from './pages/Membership';
import Marketplace from './pages/Marketplace';
import Forum from './pages/Forum';
import Partner from './pages/Partner';
import Events from './pages/Events';
import EventsGallery from './pages/EventsGallery';
import EventGalleryAlbum from './pages/EventGalleryAlbum';
import Committees from './pages/Committees';
import Magazine from './pages/Magazine';
import Terms from './pages/Terms';
import Criterions from './pages/Criterions';
import Help from './pages/Help';
import Contact from './pages/Contact';
import PartnerProfile from './pages/PartnerProfile';
import Reset from './pages/Reset';
import Corporate from './pages/Corporate';
import MagazineArticle from './pages/MagazineArticle'

import HomeHeaderBG from './assets/home_header_bg.jpg'
import GECBackground from './assets/GECBackground.png';

function App() {
  
  const [darkMode, setDarkMode] = useState(true);
  const { currentUser } = useSelector((state:any) => state.user);
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        flexGrow: 1, 
        backgroundImage: `url(${GECBackground})`,
        backgroundSize: 'cover',
        position: 'absolute',
        flexDirection: "column",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: "auto",
        maxHeight: "100%"    
      }}>
        {/* <BrowserRouter >  */}
        <BrowserRouter basename='/v2'>

        <Helmet>
          <title>German Emirates Club</title>
        </Helmet>

          <Header />
          <Navbar />
          <Box flexGrow={1} mt={'100px'} mb={'50px'} >
            <Routes>
              <Route path="/">
                <Route index element={currentUser ? <Member /> : <Homepage />} />
                <Route path="Login"  element={ <Login />} />

                <Route path="Marketplace"  element={ <Marketplace />} />
                <Route path="Membership"  element={ <Membership />} />
                <Route path="Partner"  element={ <Partner />} />
                <Route path="/Partner/:cid/:id" element={<PartnerProfile />} />
                <Route path="Forum"  element={ <Forum />} />
                <Route path="Committees"  element={ <Committees />} />
                <Route path="Events"  element={ <Events />} />
                <Route path="Events/Gallery"  element={ <EventsGallery />} />
                <Route path="Events/Gallery/:id"  element={ <EventGalleryAlbum />} />
                <Route path="Magazine"  element={ <Magazine />} />
                <Route path="Magazine/:cid/:id" element={<MagazineArticle />} />
                <Route path="Contact"  element={ <Contact />} />
                <Route path="Reset"  element={ <Reset />} />
                <Route path="Terms"  element={ <Terms />} />
                <Route path="Criterions"  element={ <Criterions />} />
                <Route path="Help"  element={ <Help />} />
                <Route path="Corporate"  element={ <Corporate />} />
              </Route>
            </Routes>
          </Box>
          <Footer />
        </BrowserRouter>
      </Box>
      
    </ThemeProvider>
  );
}

export default App;