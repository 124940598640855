import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Container, Skeleton, Typography, List, ListItem, Divider, ListItemText, Toolbar, ImageList, ImageListItem, ListSubheader, ImageListItemBar, IconButton, Grid } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import MainBg  from '../../components/MainBg';
import { Info } from "@mui/icons-material";
import axios from "axios";
import { Helmet } from 'react-helmet';
import { Masonry } from "@mui/lab";
import { height } from "@mui/system";

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography

const Partner  = () => {
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  // const [curPage, setCurPage] = useState(1);
  // const [lastPage, setLastPage] = useState(0);

  const prefUrl = 'https://www.german-emirates-club.com/uploads/sys/';
  const fetchPartners =async () => {
    const res = await axios.post('https://www.german-emirates-club.com/api/api/partners/search',{  });
    setPartners(res.data.data.rows);
  }
  useEffect(()=> {
    fetchPartners();
  },[])

  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
 
        <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
          <Paper sx={{ p: 5}} elevation={20} >
            
          <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Partner</span></TitleTexts>
          
          <Divider sx={{mb:2}}/>

          <Masonry columns={{xs:2,sm:3,md:4 }} spacing={2}>
          {   
              partners.length ? 
              partners.map((partner:any, i) => {  
                let imgPath = `${prefUrl}${partner.file}`;
                let dataUrl = `/Partner/${partner.categoryId}/${partner.id}`;
                return (
                  <div key={i} >
                    <Box key={i} sx={{
                      height:{xs:123,sm:190,md:272}, 
                      width:{xs:123,sm:190,md:272},
                      padding:'10px', 
                      border: '1px solid #CCC', 
                      display:'flex',
                      justifyContent:'center'
                    }} >
                      <Box
                        component='img'
                        src={`${imgPath}`}
                        alt={partner.title}
                        loading="lazy"
                        sx={{maxHeight:'100%', width:'100%', objectFit:'scale-down', cursor:'pointer'}}
                        onClick={() => {
                            navigate(dataUrl, {
                              state:{ 
                                partner: partner
                              }
                            })
                          }}
                        />
                    </Box>
                  </div>
                )})
              : 
              Array.from(new Array(20)).map((i) => {
              return (
                <div key={i}>
                  <Skeleton variant="rectangular" sx={{height:{xs:123,sm:190,md:272}, width:{xs:123,sm:190,md:272}}} />
                </div>
              )})
          }
          </Masonry>

        </Paper>
        </Container>

      <Helmet>
        <title>German Emirates Club | Partner</title>
        <meta name="robots" content="index, follow" />
        <meta name="Title" content="German Emirates Club | Partner"/>
      </Helmet>

    </Box>
  )
}

export default Partner;
