import axios from "axios";
import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, Paper, TextField, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import {Login, Warning} from '@mui/icons-material';
import {Cookies} from 'react-cookie';

const fontFam = 'Century Gothic,CenturyGothic,AppleGothic,sans-serif';

const LoginModal  = ({ isDialogOpened, handleCloseDialog }) => {

  const dispatch = useDispatch();
    const navigate = useNavigate();

    const formDefault = {
      value: "",
      valid: false,
      error: ""
    };
    const [login, setLogin] = useState({
      username: formDefault,
      password: formDefault,
      bookmark: {
        value: true,
        valid: false,
        error: ""
      },
    });
    
    const handleChange = (e : any) => {
      const isError = !e.target.value;
      setLogin(prev => ({ ...prev, [e.target.name]: {
        value: e.target.value,
        valid: isError,
        error: isError ? 'Bitte füllen Sie dieses Feld aus.' : ""
      }}));
    }
    
    const [formError, setFormError] = useState('');
    const handleLogin = async (e : any) => {
      try {
        e.preventDefault();
        setFormError('');

        // validate inputs first
        let hasError = false;
        Object.keys(login).forEach(field => {
          let isError = !login[field].value && field != 'bookmark';
          let errMsg = isError ? 'Bitte füllen Sie dieses Feld aus.' : '';
          if(errMsg != '') hasError = true;
          setLogin(prev => ({ ...prev, [field]: { ...login[field], valid: isError, error: errMsg } }));
        });
        if(hasError) return;

      //dispatch(loginStart());
        const reqData = {
          username: login.username.value,
          password: login.password.value,
          bookmark: login.bookmark.value
        }
        console.log('reqData', reqData);
        const res = await axios.post('./login.php', reqData); //https://www.german-emirates-club.com/api/api/auth/login 
        console.log("res: ", res);
        if(res.data.error == null ) {
          //dispatch(loginSuccess(res.data.results));
          //navigate('/');
          if(res.data.cookie) {
            const cookieObj = res.data.cookie;
            const cookies = new Cookies();
            for (const key in cookieObj) {
              const values = cookieObj[key];
              cookies.set(key, values[0], { maxAge: values[1],path: values[2] });
            }
          }
          window.location.replace('/Magazine');
        } else if(res.data.error) {
          //dispatch(loginFailure());
          setFormError(res.data.error);
        } else {
          window.location.replace('/');
        }
      } catch (error) {
        console.log(error)
        setFormError('Etwas ist schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.');
        //dispatch(loginFailure());
      }
    }


  const handleClose = () => {
    handleCloseDialog(false);
  };

  return  (
    <Modal
      open={isDialogOpened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Paper elevation={20}   
        sx={{
            p:4,
            maxWidth:450,
            mx: "auto",
            mt:'calc(50vh - 215px) ',
            outline:'none'
        }} >
          <Box>
            <Login sx={{height:'23px', mr:'8px' }} /> 
            <Typography fontSize={30} m={0} color={'#707070'} display={'inline-block'} fontFamily={fontFam} >Einloggen</Typography>
          </Box>
          <Box display={'relative'}>
            <Warning sx={{ position:'absolute' }}  />
            <Typography fontSize={15} ml={'35px'} mb={3} color={'#FF0000'} display={'inline-block'} fontFamily={fontFam}>Den vollständigen Artikel können nur eingeloggte Clubmitglieder lesen. Bitte loggen Sie sich ein!</Typography>
          </Box>
          <Grid container direction={"column"} spacing={1}>
            <Grid item>
              <TextField 
                sx={{ fontSize: 16, p:0, m:0, fontFamily:{fontFam} }}
                name="username"
                onChange={handleChange} 
                label='Nutzername' 
                placeholder='Benutzernamen eingeben' 
                variant="outlined" 
                helperText={login.password.error}
                error={login.username.valid}
                fullWidth required/>
            </Grid>
            <Grid item>
              <TextField 
                
                sx={{ fontSize: 16, p:0, m:0, mt:'24px', fontFamily:{fontFam} }}
                name="password"
                onChange={handleChange} 
                label='Passwort' 
                placeholder='Passwort eingeben' 
                type='password' 
                variant="outlined" 
                helperText={login.password.error}
                error={login.password.valid}
                fullWidth 
                required/>
            </Grid>
          </Grid>
          <Grid>
              <FormControlLabel sx={{fontFamily:{fontFam}}} control={ <Checkbox name='bookmark' value={true} defaultChecked={true} /> }  label="Speichern"  style={{fontSize:12}}  onChange={handleChange} />
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={6} justifyContent='center' sx={{textAlign:'center'}}>
              <Button onClick={handleLogin} variant="contained" sx={{
                background: 'linear-gradient(270deg, rgba(0,29,59,1) 0%, rgba(9,79,117,1) 35%, rgba(18,128,173,1) 100%)',
                borderRadius: 8,
                px: 6,
                textTransform: 'none',
                mb:1,
                fontSize:'16px',
                fontFamily:{fontFam}
              }}>Einloggen</Button>
                <Link to={'/Reset'} style={{textDecoration: 'none', color: "#436885", fontSize: 12 }}>
                  Logindaten vergessen?
              </Link>
            </Grid>
            <Grid item xs={6} alignItems={'flex-end'} sx={{textAlign:'right'}}>
              <Button onClick={handleClose} variant="contained" sx={{
                background: 'linear-gradient(106deg, rgba(239,239,239,1) 0%, rgba(170,170,170,1) 100%)',
                borderRadius: 8,
                px: 6,
                textTransform: 'none',
                mb:1,
                fontSize:'16px',
                fontFamily:{fontFam}
              }}>Schließen</Button>
            </Grid>

          </Grid>
      </Paper>
              
    </Modal>
  )
};

export default LoginModal;
