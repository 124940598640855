import React, { useState, useEffect } from "react";
import { styled, Divider, Box, Container, Paper, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    fontWeight:'bold'
  },
})) as typeof Typography

const Criterions = () => {

  return (

    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >

          <TitleTexts mb={2} ><span style={{ color:'#001b39'}}>Beitrittskriterien</span></TitleTexts>
          <Divider sx={{mb:3}} />
        
          <NormalTexts mb={2}> Der German Emirates Club bietet seinen Mitgliedern eine Mitgliedschaft, aus der Rechte und Pflichten entstehen. Um eine Exklusivität des Clubs zu wahren und ein Vertrauensverhältnis unter den Mitgliedern, sowie zwischen Mitglied und Club zu schaffen, stellt der German Emirates Club folgende Bedingungen an eine Aufnahme:</NormalTexts>
          <Box pl={2}>
            <ListTexts mb={1}>1. Die Aufnahme in den German Emirates Club kann nur auf Einladung eines bestehenden Clubmitglieds erfolgen.</ListTexts>
            <ListTexts mb={1}>2. Mitglieder müssen das 18. Lebensjahr vollendet haben.</ListTexts>
            <ListTexts mb={1}>3. Der Club möchte Deutschsprachige, die in den Vereinigten Arabischen Emiraten leben, zu einer Gemeinschaft zusammenzuführen. Somit ist die deutsche, österreichische oder Schweizer Staatsbürgerschaft die Grundvoraussetzung für eine Clubmitgliedschaft.</ListTexts>
            <ListTexts mb={1}>4. Ein Aufnahmekriterium ist der Besitz eines Residence Visas, das den ständigen Aufenthalt in den Vereinigten Arabischen Emiraten legitimiert.</ListTexts>
            {/* <ListTexts mb={2}>5. Ein Aufnahmekriterium ist der Besitz eines Residence Visas, das den ständigen Aufenthalt in den Vereinigten Arabischen Emiraten legitimiert.</ListTexts> */}
          </Box>
          <NormalTexts>Der German Emirates Club behält sich diesbezüglich Nachprüfungen vor sowie das Recht, Mitglieder bei Falschangaben auszuschließen.</NormalTexts>
        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Beitrittskriterien</title>
        <meta name="Title" content="German Emirates Club | Beitrittskriterien" />
        <meta name="robots" content="noindex,follow"/>
      </Helmet>

    </Box>
  )
}

export default Criterions;
