import * as React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import SideCard from '../SideCard';
import SideList from '../SideList';
import { Stack } from '@mui/system';

export default function Sidebar() {
  return (
    <Box flex={2}  sx={{ display: { xs: 'none', md: 'flex' }, pl: 0, ml:0}}>
      <Stack spacing={2}>
        <SideList />
        <SideCard />
      </Stack>
    </Box>
  );
}
