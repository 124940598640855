import React, { useState, useEffect} from 'react';
import { AppBar, Box , Typography, Grid, Stack, IconButton} from '@mui/material';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CreditCard, Facebook, Instagram, YouTube } from '@mui/icons-material';
import GECCardSmall from '../../assets/GECCardSmall.png';
import CorporateCard from '../../assets/CorporateCard.png';
import TermsModal from '../TermsModal'
import CriterionsModal from '../CriterionsModal';


const STypography = styled(Typography)(({ theme }) => ({
  '&': {
    color:'white',
    fontSize:'12px'
  }
})) as typeof Typography

const SLink= styled(Link)(({ theme }) => ({
  '&': {
    color:'white',
    textDecoration: 'none',
    fontSize:'12px',
    '&:hover': {
      textShadow: "1px 1px 2px #01070d",
      color:'#aabfd7',
    }
  }
})) as typeof Link

const SIconButton= styled(IconButton)(({ theme }) => ({
  '&': {
    color:'#fff',
    '&:hover': {
      textShadow: "1px 1px 2px #01070d",
      color:'#aabfd7',
      transform: 'scale(1.2)'
    }
  }

})) as typeof IconButton

export default function Footer() {

  const [isBottom, setIsBottom] = React.useState(false);
  const handleScroll = () => {
    setIsBottom(Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen2, setIsOpen2] = useState(false);
  const handleOpen2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    

    <Box bgcolor={'#001B39'}   flexGrow={1}  m={0} p={0} position="fixed" bottom={0} width='100%' zIndex={1500} height={50}>
        <Box maxWidth='1274px' sx={{color: "white", mx:'auto'}} px={{xs:2, sm:2, md: 0}} >

        <Grid container columns={{ xs: 12, sm: 12, md: 12 }}  alignItems='center' height={'50px'}> 
          <Grid item xs={11} sm={8} md={6} sx={{ display: "flex", justifyContent: { xs: "flex-center", sm:"flex-start"} }} alignItems='center'>

            <STypography display={{ xs:'none',sm:'none',md:'flex'}}> <SLink to="/" > © German Emirates Club 2007 - {new Date().getFullYear()} </SLink> </STypography>
            <STypography  display={{ xs:'none',sm:'flex', md:'none'}}> <SLink to="/" > © German Emirates Club </SLink> </STypography>
            <STypography  display={{ xs:'flex',sm:'none', md:'none'}}> <SLink to="/" > © GEC </SLink> </STypography>

            <STypography mx={1}> | </STypography>
            <STypography> <SLink onClick={() => handleOpen()} to="" >ACB </SLink>  </STypography>
            <STypography mx={1}> | </STypography>
            <STypography> <SLink onClick={() => handleOpen2()} to="" >Beitrittskriterien</SLink> </STypography>
            <SIconButton aria-label="delete" size="small" onClick={()=> window.open('https://www.facebook.com/germanemiratesclub', '_blank')}>
              <Facebook  sx={{color:'white', height:17}} />
            </SIconButton>
            <SIconButton aria-label="delete" size="small" onClick={()=> window.open('https://www.instagram.com/germanemiratesclub/', '_blank')}>
              <Instagram sx={{color:'white', height:17}} />
            </SIconButton>
            <SIconButton aria-label="delete" size="small" onClick={()=> window.open('https://www.youtube.com/channel/UCufFANQhjuNFOBqp3YQ1efQ', '_blank')}>
              <YouTube sx={{color:'white', height:17}} />
            </SIconButton>

          </Grid>

          <Grid item xs={1} sm={4} md={6} sx={{ display: "flex", justifyContent: { xs: "flex-center", sm:"flex-end"} }} alignItems={'center'}>
            <Grid  display={{ xs:'none', sm: 'flex'}}>
              <STypography display={{ sm:'none', md: 'flex'}} fontWeight={600} fontSize={12}> Corporate Card Holders, <SLink to="/Corporate" style={{fontWeight:100, marginLeft:'5px'}} > please click here</SLink></STypography>             
              <STypography display={{ sm:'flex', md: 'none'}} fontWeight={600} fontSize={12}> Corporate Card Holders</STypography>             
            </Grid>
            <Grid >
            <SLink to="/Corporate" ><SIconButton sx={{ }}><Box component="img" src={CorporateCard}  width={'50px'} height={'32px'} /> </SIconButton>  </SLink>       
            </Grid>
          </Grid>
        </Grid>
    
        </Box>

        <TermsModal
          isDialogOpened={isOpen}
          handleCloseDialog={() => setIsOpen(false)}
        />
        <CriterionsModal
          isDialogOpened={isOpen2}
          handleCloseDialog={() => setIsOpen2(false)}
        />

    </Box>
    
  );
}
