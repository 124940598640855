import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { persistor, store } from './redux/store';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import './fonts/century-gothic/CenturyGothic.ttf';
import './fonts/trajanpro/TrajanPro-Bold.otf';
import './fonts/trajanpro/TrajanPro-Regular.otf';
import './styles'
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <Provider store= {store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);