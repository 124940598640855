import React, {  } from 'react';
import {styled, Paper, Divider, Accordion, AccordionDetails, AccordionSummary, Typography, Box, Container } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography

const Help = () => {

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  

  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >

        <Paper sx={{ p: 5}} elevation={20} >

        <TitleTexts mb={2} ><span style={{ color:'#436885'}}>Hilfe</span></TitleTexts>
        <Divider sx={{mb:3}} />

        <NormalTexts color={'#436885'} mb={2} >Häufig gestellte Fragen</NormalTexts>

        <Accordion disabled  sx={{ p:0, borderRadius:'none' }} elevation={0} >
          <AccordionSummary aria-controls="panel0a-content" id="panel0a-header" >
            <NormalTexts color={'#000'} > Login </NormalTexts>
          </AccordionSummary>
        </Accordion>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
         <NormalTexts color={'#436885'}> Ich habe meine Zugangsdaten vergessen. Was kann ich tun? </NormalTexts>
          </AccordionSummary>
          <AccordionDetails>
            <NormalTexts mb={2}>Wenn Sie Ihren Benutzernamen bisher nicht geändert haben, ist die Emailadresse mit der Sie sich im GEC registriert haben, Ihr Benutzername für den Login.</NormalTexts>
            <NormalTexts mb={2}>Bitte achten Sie auf die korrekte Schreibweise. GROß- und klein-Schreibung wird unterschieden.</NormalTexts>
            <NormalTexts mb={2}>Cookies müssen aktiviert sein.</NormalTexts>
            <NormalTexts mb={2}>Bitte achten Sie auf eventuelle LEERZEICHEN am Ende bzw. am Anfang Ihrer Daten.</NormalTexts>
            <NormalTexts mb={2}>Wenn Sie Ihre Logindaten vergessen haben, klicken Sie auf folgenden Link: http://www.german-emirates-club.com/Reset.</NormalTexts>
            <NormalTexts mb={2}>Um beim nächsten Login automatisch eingeloggt zu werden, müssen Cookies aktiviert sein.</NormalTexts>
            <NormalTexts mb={2}>Wenn Sie noch kein Mitglied im GEC sind, warten Sie bitte auf eine Einladung eines Bekannten oder sprechen Sie jemanden an, der bereits Mitglied im GEC ist.</NormalTexts>
          </AccordionDetails>
        </Accordion>


        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Häufig gestellete Fragen</title>
        <meta name="Title" content="German Emirates Club | Häufig gestellete Fragen"/>
        <meta name="robots" content="noindex,follow"/>
      </Helmet>

    </Box>
  );
}

export default Help;
