import axios from "axios";
import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, Paper, TextField, Typography,  makeStyles, styled, Divider, List, ListItemText, Fab, Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, DialogProps } from '@mui/material';
import { Navigation } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import {Login, Warning} from '@mui/icons-material';
import {Cookies} from 'react-cookie';

const fontFam = 'Century Gothic,CenturyGothic,AppleGothic,sans-serif';

const MainTitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#001b39',
    fontWeight: 800,
    fontSize:22,
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
  },
})) as typeof ListItemText
const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const ListTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#436885',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    fontWeight:'bold'
  },
})) as typeof Typography

const TermsModal  = ({ isDialogOpened, handleCloseDialog }) => {

  const [ showGoTop, setShowGoTop ] = useState(false);
  const handleVisibleButton = () => {
      setShowGoTop(window.pageYOffset > 500)
  }
  const goToTop = () => {
      window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
  }
  useEffect( () => {
      window.addEventListener( 'scroll', handleVisibleButton )
  }, [] );

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {
    handleCloseDialog(false);
  };

  return  (
    <Dialog
        open={isDialogOpened}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        //fullWidth={fullWidth}
        maxWidth={'lg'}
        sx={{height:'87%', mt:'75px'}}
      >
        <DialogTitle id="scroll-dialog-title"><MainTitleTexts>Beitrittskriterien</MainTitleTexts></DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          
            <NormalTexts mb={2}> Der German Emirates Club bietet seinen Mitgliedern eine Mitgliedschaft, aus der Rechte und Pflichten entstehen. Um eine Exklusivität des Clubs zu wahren und ein Vertrauensverhältnis unter den Mitgliedern, sowie zwischen Mitglied und Club zu schaffen, stellt der German Emirates Club folgende Bedingungen an eine Aufnahme:</NormalTexts>
            <Box pl={2}>
              <ListTexts mb={1}>1. Die Aufnahme in den German Emirates Club kann nur auf Einladung eines bestehenden Clubmitglieds erfolgen.</ListTexts>
              <ListTexts mb={1}>2. Mitglieder müssen das 18. Lebensjahr vollendet haben.</ListTexts>
              <ListTexts mb={1}>3. Der Club möchte Deutschsprachige, die in den Vereinigten Arabischen Emiraten leben, zu einer Gemeinschaft zusammenzuführen. Somit ist die deutsche, österreichische oder Schweizer Staatsbürgerschaft die Grundvoraussetzung für eine Clubmitgliedschaft.</ListTexts>
              <ListTexts mb={1}>4. Ein Aufnahmekriterium ist der Besitz eines Residence Visas, das den ständigen Aufenthalt in den Vereinigten Arabischen Emiraten legitimiert.</ListTexts>
              {/* <ListTexts mb={2}>5. Ein Aufnahmekriterium ist der Besitz eines Residence Visas, das den ständigen Aufenthalt in den Vereinigten Arabischen Emiraten legitimiert.</ListTexts> */}
            </Box>
            <NormalTexts>Der German Emirates Club behält sich diesbezüglich Nachprüfungen vor sowie das Recht, Mitglieder bei Falschangaben auszuschließen.</NormalTexts>

          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
  )
};

export default TermsModal;
