import React, { useState, useEffect } from "react";
import { styled, Paper, Box, Divider, Container, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { Masonry } from '@mui/lab';
import axios from "axios";
import { Image } from 'primereact/image';
import { Helmet } from 'react-helmet';
 

const TitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#fe7b00',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography
const NormalTexts = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize:20,
    color: '#000',
    fontFamily:"Century Gothic,CenturyGothic,AppleGothic,sans-serif"
  },
})) as typeof Typography
const SubTitleTexts = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#436885',
    fontWeight: 800,
    fontSize:22
  },
})) as typeof Typography

const ImgBox = styled(Box)(({ theme }) => ({
  '&': {
    maxHeight:'100%', 
    width:'100%', 
    objectFit:'cover', 
    cursor:'pointer',
    "&:hover": {
      // opacity: 0.8
      transition: 'all 10ms ease',
      boxShadow: '0 25px 50px -25px rgba(0, 0, 0, .5) inset',
      backgroundColor:'#000',
     // opacity:0.9,
      filter: 'brightness(50%)'
    },
  },
})) as typeof Box

const EventsGallery  = () => {

  const navigate = useNavigate();
  const [gallery, setGallery] = useState({});

  const prefUrl = 'https://www.german-emirates-club.com/uploads/sys/';

  useEffect(() => {
    const fetchEventsGallery = async () => {
      const res = await axios.get('https://www.german-emirates-club.com/api/api/events/gallery-partial');
      
      setGallery(res.data.data);
      for(const key in res.data.dat) {
        console.log(`${key} -> ${res.data.dat[key]}`)
      }
      console.log('res.data.data', res.data.data)
    };
    fetchEventsGallery();
  },[])

  return (
    <Box position="relative" sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.32)',
      minHeight:'calc(100vh - 150px) ',
    }}>
      <Container sx={{ mX:0, pX:0, py: 4, maxWidth:1247, position: "relative" }} >
        <Paper sx={{ p: 5}} elevation={20} >
            
          <TitleTexts mb={2} >GEC <span style={{ color:'#001b39'}}>Mitgliedergalerie</span></TitleTexts>
          <Divider sx={{mb:3}} />

          <NormalTexts mb={3}>
          Mehr als 80% der in den Vereinigten Arabischen Emiraten lebenden Deutschen, Österreicher und Schweizer sind Mitglied im German Emirates Club. Monatlich lädt der Club seine Mitglieder zu unterschiedlichsten Veranstaltungen ein und führt die deutschsprachige Clubgesellschaft zusammen. Nachfolgend haben wir Ihnen einige ausgewählte Fotos unserer Mitglieder und Events zusammengestellt. Tauchen Sie ein in die Atmosphäre, die Sie erwartet.
          </NormalTexts>

            {
              gallery && gallery[0] ? 
                <Box>
                  {
                    gallery[0] ? 
                    <Box>
                      <Divider sx={{marginBottom:1}} textAlign="left"> <SubTitleTexts>{gallery[0][0].catName}</SubTitleTexts> </Divider>
                      <Masonry columns={{xs:2,sm:3,md:4 }} spacing={2}>
                      {   
                          gallery[0].map((gal:any, i) => {  
                            let imgPath = `${prefUrl}${gal.file}`;
                            let dataUrl = `/Events/Gallery/${gal.catId}`;
                            return (
                              <div key={i} >
                                <Box key={i} sx={{
                                  height:{xs:123,sm:190,md:290}, 
                                  width:{xs:123,sm:190,md:292},
                                  padding:'10px', 
                                  border: '1px solid #CCC', 
                                  display:'flex',
                                  justifyContent:'center'
                                }} >
                                  <ImgBox
                                    component='img'
                                    src={`${imgPath}`}
                                    alt={gal.catName}
                                    loading="lazy"
                                    sx={{maxHeight:'100%', width:'100%', objectFit:'cover', cursor:'pointer'}}
                                    onClick={() => {
                                        navigate(dataUrl, {
                                          state:{ 
                                            id: gal.catId
                                          }
                                        })
                                      }}
                                    />
                                </Box>
                              </div>
                            )})
                      }
                      </Masonry>
                    </Box>
                    : ''
                  }

                  {
                    gallery[1] ? 
                    <Box>
                      <Divider sx={{marginBottom:1}} textAlign="left"> <SubTitleTexts>{gallery[1][0].catName}</SubTitleTexts> </Divider>
                      <Masonry columns={{xs:2,sm:3,md:4 }} spacing={2}>
                      {   
                          gallery[1].map((gal:any, i) => {  
                            let imgPath = `${prefUrl}${gal.file}`;
                            let dataUrl = `/Events/Gallery/${gal.catId}`;
                            return (
                              <div key={i} >
                                <Box key={i} sx={{
                                  height:{xs:123,sm:190,md:290}, 
                                  width:{xs:123,sm:190,md:292},
                                  padding:'10px', 
                                  border: '1px solid #CCC', 
                                  display:'flex',
                                  justifyContent:'center'
                                }} >
                                  <ImgBox
                                    component='img'
                                    src={`${imgPath}`}
                                    alt={gal.catName}
                                    loading="lazy"
                                    sx={{maxHeight:'100%', width:'100%', objectFit:'cover', cursor:'pointer'}}
                                    onClick={() => {
                                        navigate(dataUrl, {
                                          state:{ 
                                            id: gal.catId
                                          }
                                        })
                                      }}
                                    />
                                </Box>
                              </div>
                            )})
                      }
                      </Masonry>
                    </Box>
                    : ''
                  }


                  {
                    gallery[2] ? 
                    <Box>
                      <Divider sx={{marginBottom:1}} textAlign="left"> <SubTitleTexts>{gallery[2][0].catName}</SubTitleTexts> </Divider>
                      <Masonry columns={{xs:2,sm:3,md:4 }} spacing={2}>
                      {   
                          gallery[2].map((gal:any, i) => {  
                            let imgPath = `${prefUrl}${gal.file}`;
                            let dataUrl = `/Events/Gallery/${gal.catId}`;
                            return (
                              <div key={i} >
                                <Box key={i} sx={{
                                  height:{xs:123,sm:190,md:290}, 
                                  width:{xs:123,sm:190,md:292},
                                  padding:'10px', 
                                  border: '1px solid #CCC', 
                                  display:'flex',
                                  justifyContent:'center'
                                }} >
                                  <ImgBox
                                    component='img'
                                    src={`${imgPath}`}
                                    alt={gal.catName}
                                    loading="lazy"
                                    sx={{maxHeight:'100%', width:'100%', objectFit:'cover', cursor:'pointer'}}
                                    onClick={() => {
                                        navigate(dataUrl, {
                                          state:{ 
                                            id: gal.catId
                                          }
                                        })
                                      }}
                                    />
                                </Box>
                              </div>
                            )})
                      }
                      </Masonry>
                    </Box>
                    : ''
                  }

                  {
                    gallery[3] ? 
                    <Box>
                      <Divider sx={{marginBottom:1}} textAlign="left"> <SubTitleTexts>{gallery[3][0].catName}</SubTitleTexts> </Divider>
                      <Masonry columns={{xs:2,sm:3,md:4 }} spacing={2}>
                      {   
                          gallery[3].map((gal:any, i) => {  
                            let imgPath = `${prefUrl}${gal.file}`;
                            let dataUrl = `/Events/Gallery/${gal.catId}`;
                            return (
                              <div key={i} >
                                <Box key={i} sx={{
                                  height:{xs:123,sm:190,md:290}, 
                                  width:{xs:123,sm:190,md:292},
                                  padding:'10px', 
                                  border: '1px solid #CCC', 
                                  display:'flex',
                                  justifyContent:'center'
                                }} >
                                  <ImgBox
                                    component='img'
                                    src={`${imgPath}`}
                                    alt={gal.catName}
                                    loading="lazy"
                                    sx={{maxHeight:'100%', width:'100%', objectFit:'cover', cursor:'pointer'}}
                                    onClick={() => {
                                        navigate(dataUrl, {
                                          state:{ 
                                            id: gal.catId
                                          }
                                        })
                                      }}
                                    />
                                </Box>
                              </div>
                            )})
                      }
                      </Masonry>
                    </Box>
                    : ''
                  }

                </Box>
              : 
              <Box>
                <Divider sx={{marginBottom:3}} textAlign="left"><Skeleton variant="text" sx={{ fontSize: '22px' }} /></Divider>
                <Masonry columns={{xs:2,sm:3,md:4 }} spacing={2}>
                {
                  Array.from(new Array(4)).map((i) => {
                    return ( <div key={i}><Skeleton variant="rectangular" sx={{ width:{xs:123,sm:190,md:290}, height:{xs:123,sm:190,md:292} }} /> </div>  )
                  })
                }
                </Masonry>
              </Box>
            }
              

        </Paper>
      </Container>

      <Helmet>
        <title>German Emirates Club | Gallerie</title>
        <meta name="Title" content="German Emirates Club | Gallerie"/>
        <meta name="robots" content="index, follow"/>
      </Helmet>

    </Box>
  )
}


export default EventsGallery;
